
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

/* comman-classes */

a {
  text-decoration: none !important;
}
.blue-btn a {
  border-radius: 10px;
  background: #fff;
  padding: 18px 28px;
  border: 1px solid #4a67ab !important;
  color: #4a67ab !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
}
.blue-btn a:hover {
  background: #4a67ab;
  border: 1px solid #fff !important;
  color: #fff !important ;
}
.mbottom {
  margin-bottom: 2rem;
}
a.cblack {
  color: #223b19;
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
}
.glow-blue-btn a {
  border-radius: 12px;
  background: #5165ac;
  color: #fff;
  padding: 16px 28px;
  font-size: 14px;
  font-weight: 500;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  line-height: 1.2;
  text-transform: uppercase;
  border: 1px solid #fff;

}
.glow-blue-btn a:hover {
  border: 1px solid #5165ac;
  color: #4a67ab;
  background: #fff;
}
.glow-whit-btn a {
  border-radius: 12px;
  border: 1px solid #5165ac;
  color: #4a67ab;
  padding: 15px 17px;
  font-size: 14px;
  font-weight: 500;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  line-height: 1.2;
  text-transform: uppercase;
}
span.color-red {
  color: red;
}
#google-location [class$="-IndicatorsContainer"],
#google-location [class$="-indicatorContainer"],
#google-location [class$="-indicatorSeparator"] {
  display: none;
}
.error-message,
.error-location {
  color: red;
}
.Join.Waitlist-message {
  margin-top: 1rem;
  font-size: 1.2rem;
  text-align: center;
  color: #29921a;
}
.error-location {
  text-align: center;
}
img.gapplogo {
  width: 38%;
}
.glow-whit-btn a:hover {
  background: #5165ac;
  color: #fff;
}
.nb-button.active {
  border: 1px solid #fff;
  background: #fff;
  color: #030306;
}
.visit-button {
  border: none;
  text-align: center;
  border-radius: 9px;
  background: #fff;
  padding: 15px 20px;
  color: #5165ac !important;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}
.visit-our-website {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  margin-bottom: 5rem;
}
.close-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
img.chome-logo {
  width: 80%;
}

.our-blog .blue-btn {
  border-radius: 10px;
  background: #5165ac;
  padding: 16px 28px;
  color: #eaeaea;
  width: 100%;
  margin-top: 1.2rem;
  border: #5165ac;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
}
.whit-btn a {
  border-radius: 10.98px;
  border: 1.5px solid #fff;
  background: rgba(255, 255, 255, 0);
  padding: 14px 16px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
}
.whit-btn a:hover {
  color: #fff;
}
a.Subscribe-btn:hover {
  background: #5165ac;
  color: #fff;
}
.our-blog ::-webkit-input-placeholder {
  color: #a7a2a2;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.our-blog ::-moz-placeholder {
  color: #a7a2a2;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.our-blog :-ms-input-placeholder {
  color: #a7a2a2;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.our-blog ::-ms-input-placeholder {
  color: #a7a2a2;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.our-blog ::placeholder {
  color: #a7a2a2;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.contact-button {
  border-radius: 20px;
  background: #5165ac;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  border: none;
  padding: 20px 25px;
  -webkit-transition: 0, 3s;
  -o-transition: 0, 3s;
  transition: 0, 3s;
}
.contact-button:hover {
  background: #fff;
  color: #5165ac;
  border: 1px solid #5165ac;
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
.font-size {
  font-size: 30px;
}
input#otherTextField {
  border-radius: 12px;
  background: #f9faff;
  border: 0;
  padding: 18px 20px;
  width: 69%;
}
/* model-sec */

.modal-content {
  margin-top: 10rem;
}

/* header-sec */
section.header.header-bg {
  position: fixed;
  z-index: 999999;
  top: 0;
  width: 100%;
}
section.header {
  position: fixed;
  top: 0;
  margin: auto;
  width: 100%;
  padding-top: 15px;
  z-index: 999;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown button {
  background-color: transparent;
  color: #ffffffb0;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  -webkit-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 150px;
  padding: 8px 14px;
  border-radius: 10px;
}

/* Style for each dropdown item */
.dropdown-content-name {
  color: #242424;
  padding-bottom: 5px;
  display: block;
  text-decoration: none;
}

/* Change color on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown content when the dropdown button is hovered over */
.dropdown:hover .dropdown-content {
  display: block;
}
section.header.header-bg {
  background: #4a67ab;
  padding: 0;
}
/* .navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 1.5rem !important;
} */
div#basic-navbar-nav {
  width: 40%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

span.navbar-brand {
  width: 20%;
}
.header-button {
  width: 40%;
  text-align: end;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(255 255 255 / 74%) !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #ffffff !important;
}
a.nb-button:hover {
  border: 1px solid #fff;
  background: #fff;
  color: #030306;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff !important;
}
a.nb-button {
  color: #fff;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 400;
  padding: 10px 20px;
  line-height: 1.2;
}
.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
}
.dropdown-menu.show {
  border-radius: 16px;
}
.get-app {
  border-radius: 6.935px;
  border: 1px solid #FFF;
  background: #FFF;
  padding: 10px 12px;
  position: relative;
  z-index: 999;
  display: flex;
  color: #4A67AB;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  width: fit-content; 
  margin: auto;
  gap: 12px;
}

.hover-qr {
  width: fit-content;
  margin: auto;
  overflow: hidden;
  height: 0px;
  opacity: 0;
  transition: opacity 0.3s ease;
  object-fit: contain;
  position: absolute;
    top: -230px;
    left: 50%;
    transform: translate(-50%, 0px);
}

.get-app:hover + .hover-qr{
opacity: 1;
height: 220px;
}
.hover-qr:hover{
  opacity: 1;
  height: 220px;
}
.inner-get-app {
  margin-top: 3rem;
  display: flex;
    flex-direction: column-reverse;
    position: relative;
}
.gapp-bottom-title {
  color: #ffffff8a;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
}
.t-c {
  color: #fff;
}
img.header-logo {
  width: 80%;
}
.dropdown-menu {
  min-width: 8rem !important;
}
.dropdown-item {
  font-size: 14px !important;
  font-family: "Poppins" !important;
}
.dropdown-toggle::after {
  display: none !important;
}

/* footer-sec */

.footer-sec {
  background: #4c66b1;
  padding-top: 50px;
  padding-bottom: 25px;
}
.subscripe-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  gap: 12px;
}
.footer-social-payment img {
  width: 60%;
}
.footer-bottom-heading {
  color: #ffffff9e;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  padding-top: 2rem;
}
img.footer-bottom-link {
  width: 100%;
  max-width: 78%;
}
img.footer-logo {
  width: 64%;
}
.help-main {
  padding-left: 26px;
}
.Who-we-are-main {
  margin-left: -16px;
}
.footer-inner-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.32);
  padding-top: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 6px;
}
a .list-title {
  color: rgb(255 255 255 / 76%);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  list-style-type: none !important;
  padding-bottom: 16px;
}
.footer-social-link {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 9px;
}
.footer-social-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
  padding-top: 25px;
}
button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  display: block !important;
}
.footer-main {
  color: rgba(255, 255, 255, 0.88);
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}
#subscripe input {
  border-radius: 10px;
  border: 1px solid #fff;
  -webkit-box-shadow: -13.01111px 13.01111px 104.08887px 0px rgba(161, 161, 161, 0.1);
          box-shadow: -13.01111px 13.01111px 104.08887px 0px rgba(161, 161, 161, 0.1);
  background: #4c66b1;
  padding: 10px 20px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 33px;
}
.Subscribe input::-webkit-input-placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
.Subscribe input::-moz-placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
.Subscribe input:-ms-input-placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
.Subscribe input::-ms-input-placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
.Subscribe input::placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
a.Subscribe-btn {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: #fff;
  color: #5165ac;
  font-size: 13px;
  font-weight: 700;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  line-height: 1.2;
  text-transform: uppercase;
  padding: 15px 33px;
}
.checkbox-msg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  gap: 9px;
}
.Subscribe .checkbox-msg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 9px;
}
label.checkbox-desc {
  color: #ffffffba;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}
.thank-you-message {
  color: #ff3c3c;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 20px;
  letter-spacing: 0.1px;
}
.Join-Waitlist-message {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 20px;
}

/* home-page */


/* welcome-page */

.welcome-logo {
  text-align: center;
  padding-top: 26px;
  position: relative;
  z-index: 9;
}
.welcome-inner-right {
  background-image: url("../src/image/welcome-right.webp");
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 150px 100px;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
  
}
.welcome-inner-left{
  background-image: url("../src/image/welcome-left.webp");
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 150px 100px;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
}
.welcomepage-title {
    color: #FFF;
    text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.10);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    padding: 10px 0px 40px;
}
.welcomepage-sec .col-lg-6 {
  padding: 0px;
}
.welcomepage-sec .col-lg-12 {
  padding: 0px;
}
.welcomepage-heading {
  color: #FFF;
  text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.10);
  font-family: Poppins;
  font-size: 42px;
  font-weight: 800;
  line-height: 1.2;
}
.welcome-inner {
  margin-top: -104px;
  position: relative;
  z-index: 2;
}



/* banner-sec */

section.banner-sec .container {
  padding: 0 100px;
}

img.social-link {
  width: 100%;
}
img.award-img {
  width: 100%;
  max-width: 26%;
}
.banner-sec {
  background-color: #4A67AB;
  padding: 120px 0px 370px;
  margin-top: 0rem;
}
.banner-right img {
  width: 100%;
}
.banner-heading {
  color: #fff;
  font-size: 52px;
  font-weight: 800;
  line-height: 1.2;
}
.banner-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
.banner-left-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 24px;
  margin-top: 6%;
}
.banner-social-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
  position: relative;
  z-index: 9;
}
.click_desc {
  color: #FFF;
  font-size: 24px;
  font-weight: 700;
  padding: 16px 0px 10px;
  margin-bottom: 0;
}
.banner-social-link a {
  width: 30%;
}

/* banner-arch-sec */
.banner-slider-sec {
  overflow: hidden;
  margin-top: -28rem;
  padding-bottom: 65px;
  position: relative;
  z-index: 1;
}
.as-i-see {
  background: #f9faff;
  padding-top: 12rem;
  margin-top: -16rem;
  padding-bottom: 5rem;
}
.banner-slider-sec-inner {
  position: relative;
  padding-top: 33rem;
  margin-top: 12rem;
}

img.banner-slide-img {
  position: absolute;
  border-radius: 20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
img.banner-slide-img:nth-child(1) {
  top: 30%;
  -webkit-transform: rotate(345deg);
      -ms-transform: rotate(345deg);
          transform: rotate(345deg);
  width: 18%;
  left: -58px;
}
img.banner-slide-img:nth-child(2) {
  top: 16%;
  width: 18%;
  left: 11%;
  -webkit-transform: rotate(352deg);
      -ms-transform: rotate(352deg);
          transform: rotate(352deg);
  z-index: 5;
}
img.banner-slide-img:nth-child(3) {
  top: 5%;
  width: 18%;
  left: 26%;
  z-index: 4;
  -webkit-transform: rotate(352deg);
      -ms-transform: rotate(352deg);
          transform: rotate(352deg);
}
img.banner-slide-img:nth-child(4) {
  top: 0;
  width: 18%;
  left: 42%;
  z-index: 3;
}
img.banner-slide-img:nth-child(5) {
  top: 7%;
  right: 25%;
  width: 18%;
  z-index: 2;
  -webkit-transform: rotate(8deg);
      -ms-transform: rotate(8deg);
          transform: rotate(8deg);
}
img.banner-slide-img:nth-child(6) {
  top: 21%;
  right: 9%;
  width: 18%;
  z-index: 1;
  -webkit-transform: rotate(8deg);
      -ms-transform: rotate(8deg);
          transform: rotate(8deg);
}
img.banner-slide-img:nth-child(7) {
  top: 33%;
  right: -53px;
  width: 18%;
  -webkit-transform: rotate(14deg);
      -ms-transform: rotate(14deg);
          transform: rotate(14deg);
}
.blue-white-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 12px;
  padding-top: 3rem;
}
img.banner-slide-img:hover {
  z-index: 99;
  -webkit-transform: rotate(0deg) scale(1.1) translateY(-60px);
      -ms-transform: rotate(0deg) scale(1.1) translateY(-60px);
          transform: rotate(0deg) scale(1.1) translateY(-60px);
}
img.banner-slide-img:nth-child(2):hover {
  -webkit-transform: rotate(360deg) scale(1.1) translateY(-60px);
      -ms-transform: rotate(360deg) scale(1.1) translateY(-60px);
          transform: rotate(360deg) scale(1.1) translateY(-60px);
}
img.banner-slide-img:nth-child(3):hover {
  -webkit-transform: rotate(360deg) scale(1.1) translateY(-60px);
      -ms-transform: rotate(360deg) scale(1.1) translateY(-60px);
          transform: rotate(360deg) scale(1.1) translateY(-60px);
}
img.banner-slide-img:nth-child(1):hover {
  -webkit-transform: rotate(360deg) scale(1.1) translateY(-60px);
      -ms-transform: rotate(360deg) scale(1.1) translateY(-60px);
          transform: rotate(360deg) scale(1.1) translateY(-60px);
}
.as-i-see-haeding {
  color: #5165ac;
  text-align: center;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
.as-i-see-inner img {
  width: 10%;
  margin-left: 18px;
  -webkit-box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
}
.as-i-see-img {
  padding-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* slider-sec */

.slider-sec {
  padding-bottom: 68px;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  display: none;
}
button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  display: none;
}
.slider-sec-inner {
  padding-bottom: 32px;
}
.slider2 {
  padding-top: 6rem;
}
.slider2 {
  padding: 2rem 0px 1.5rem;
  margin-top: 5rem;
  border-top: 2px solid rgba(76, 102, 177, 0.05);
  border-bottom: 2px solid rgba(76, 102, 177, 0.05);
}
.carousel-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  gap: 5px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}
.slider1 .carousel-slider-inner {
  height: 100%;
}
.slider1 .carousel-slider-inner img {
  width: 100%;
}
.slider1 li.react-multi-carousel-item {
  margin-right: 30px;
}
.carousel-img-name {
  color: #4c66b1;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  margin-top: 9px;
}
.multi-slider-title {
  color: #5165AC;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  padding: 10px 14px 0px;
  margin-bottom: 0;

}
.sliderContentCont{
}
.multi-slider-desc {
  color: #7E7E7E;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6 ;
  padding: 7px 14px 7px 9px;

}
.carousel-slider-inner {
  border-radius: 10px;
  border: 2px solid #D8E0FF;
}

/* #google-location [class$="-indicatorSeparator"]  */
.multi-select .css-1nmdiq5-menu {
  z-index: 9999;
  position: relative;
}

.css-1nmdiq5-menu input {
  margin-right: 0.5rem;
}

/* services-sec */
.service-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 3rem;
}
.service-award {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
.service-link img {
  width: 155px;
  border-radius: 12px;
  /* background: lightgray -22.516px -27.687px / 239.031% 188.976% no-repeat;
  box-shadow: 0px 6.444px 18.411px 0px rgba(0, 0, 0, 0.07); */
}
.services-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
.services-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.scanner-service img {
  max-width: 70%;
}
.service-award img {
  width: 100%;
  max-width: 40%;
}
img.services-left-img {
  width: 100%;
  max-width: 78%;
}
.service-sec-img {
  width: 100%;
  object-fit: cover;
/* height: 500px; */
} 
.access-service-img{
  width: 100%;
  height: 100%;
  display: flex;
}
.services-sec .col-lg-12{
  padding: 0;
}
.service-firstCont {
padding-left:151px;
padding-top:90px ;
padding-bottom: 90px;
width: 50%;
}
.service-firstCont-title {
  color: #FFF;
  font-family: Poppins;
  font-size: 43px;
  font-style: normal;
  font-weight: 800;
  line-height:1.4;
  margin-top: 15px;
}
.service-firstCont-para {
  margin-top: 40px;
  color: #FFF;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2; /* 31.772px */
}
.service-firstCont-rating {
  color: #FFF;
  font-family: Poppins;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 40px;
  margin-bottom: 20px;
}
.service-firstCont-reviews {
  color: #FFF;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 15px;
}

.imgService-cont img {
  width:100%;
}
.getStart{
  padding-top:20px
}


/* slick-slider */

/* SwiperSlider.css */

.Vendoir-different {
  padding-top: 70px;
  padding-bottom: 90px;
}
.Vendoir-heading {
  text-align: center;
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 60px;
}
.Vendoir-different-left {
  position: relative;
  text-align: center;
  width: 50%;
  margin: auto;
}
.Vendoir-different .swiper-backface-hidden .swiper-slide {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  padding-top: 25px;
}
.vendior-works-desc {
  color: #7E7E7E;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 0px;
}
.vendior-works-heading {
  color: #242424;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
}
.vendior-works-checkbox {
  position: relative;
  border-radius: 15.765px;
  border: 1.752px solid #5165AC;
  padding: 18px 32px;
  margin-bottom: 18px;
  cursor: pointer;
}
.vendior-works-checkbox.active{
  background: #5165AC;   
}
.vendior-works-checkbox.active .vendior-works-heading{
  color: #fff;   
}
.vendior-works-checkbox.active .vendior-works-desc{
  color: #fff;   
}

.vendior-works-checkbox:last-child::before{
  display: none;
}
label.vendior-works-heading {
  color: #242424;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
}
input.checkbox-round {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  margin-right: 8px;
  border: none;
  outline: 1px solid #000;
  background: #fff;
}
.checkbox-round:checked {
  background-color: #5165AC;
  background-image: url("../src/image/tick-image.png");
  background-repeat: no-repeat;
  background-position: center
}
/* Sustainability */

.Sustainability-sec {
  background: #57C055;
  padding-top: 80px;
  padding-bottom: 50px;
}
.Sustainability-heading {
  color: #fff;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 12px;
}
.Sustainability-title {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
}
.Sustainability-desc {
  color: #ffffff69;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}
.coming-soon {
  color: #ffffff1f;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
.Sustainability-inner {
  padding-top: 2%;
}
.Sustainability-upper {
  text-align: center;
}
.Sustainability-card {
  border-radius: 18px;
  border: 2px solid #f9faff;
  color: #fff;
  padding: 20px 18px;
  margin-bottom: 25px;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.Sustainability-card-icon {
  border-radius: 10px;
  background: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  padding: 10px 16px;
}
.Sustainability-card:hover .Sustainability-card-icon svg path {
  fill: #fff;
}
.Sustainability-card:hover .Sustainability-card-icon {
  background: #41A15C;
}
.Sustainability-card:hover .Sustainability-card-desc p {
  color: #7e7e7e;
}
.Sustainability-card:hover .Sustainability-card-desc h4 {
  color: #41A15C;
}
.Sustainability-card:hover {
  background-color: #fff;
}

.Sustainability-card-desc p {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.Sustainability-card-desc h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 900;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  line-height: 1.2;
}
.Sustainability-card-lern a {
  display: none;
  color: #5165ac;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2; /* 311.111% */
}
.Sustainability-inner-right img {
  max-width: 80%;
}

/* glow-up-sec */

.glow-up-sec {
  padding-top: 60px;
  padding-bottom: 76px;
}
.glow-up-card-new.text-center {
  border-radius: 26.25px;
  background: #FFF;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 22px;
  margin: 2rem 0rem;
}
.glow-up-card-heading {
  color: #242424;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  padding: 25px 0px 10px;
}
.glow-img img {
  border-radius: 26px;
}


.glow-up-card-title {
  color: #7E7E7E;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.glow-up-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
.glow-up-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.glow-up-card {
  border-radius: 30px;
  background: -webkit-gradient(linear, left bottom, left top, from(#8597d6), to(#8597d6)),
    rgba(0, 0, 0, 0.5);
  background: -o-linear-gradient(bottom, #8597d6 0%, #8597d6 100%),
    rgba(0, 0, 0, 0.5);
  background: linear-gradient(0deg, #8597d6 0%, #8597d6 100%),
    rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 10px 0px 15px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 10px 0px 15px 0px rgba(0, 0, 0, 0.25);
  padding: 35px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.glow-icon {
  border-radius: 26px;
  background: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px;
  margin: auto;
  margin-bottom: 17px;
}
.glow-up-card-inner-heading {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  padding-bottom: 12px;
}
.glow-up-card-inner-title {
  color: #e4e4e4;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}
.glow-up-card:hover {
  background: -webkit-gradient(linear, left bottom, left top, from(#5165ac), to(#5165ac)),
    rgba(0, 0, 0, 0.5);
  background: -o-linear-gradient(bottom, #5165ac 0%, #5165ac 100%),
    rgba(0, 0, 0, 0.5);
  background: linear-gradient(0deg, #5165ac 0%, #5165ac 100%),
    rgba(0, 0, 0, 0.5);
}

/* cards-dec */

.cards-sec {
  background: #5165ac;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.cards-sec-inner img {
  width: 100%;
  max-width: 12%;
}
.glow-blue-white-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 12px;
  padding-top: 2rem;
}
img.cards-icon {
  width: 100%;
  max-width: 14%;
  padding-bottom: 10px;
}
.cards-sec-inner {
  border-radius: 30px;
  background: #fff;
  padding: 24px 39px;
}
.cards-sec-inner2 {
  border-radius: 30px;
  background: #d5dffb;
  padding: 20px 41px;
}
.cards-sec-inner-question {
  color: #5165ac;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 0px;
  line-height: 1.2;
  padding-top: 6px;
  margin: 0;
  text-transform: capitalize;
}
.card-list-desc {
  color: #5165ac;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.5;
}

/* vendior-different */
.vendior-different-sec {
  background: #F9FAFF;
  padding-top: 60px;
  padding-bottom: 60px;
}
.vendior-differnet-img  img {
  border-radius: 26px;
  width: 80%;
}
.vendior-different-inner {
  padding-top: 3rem;
}
.vendior-different-heading {
  color: #5165AC;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.2;
}
.vendior-different-list-heading {
  color: #242424;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
}
.vendior-different-list {
  color: #555;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.2;
}

/* premium-sec */

.premium-sec {
  padding-top: 30px;
}
.premium-sec-heading.text-center {
  color: #5165AC;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.2;
}
.premium-heading {
  color: #5165AC;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  padding-top: 20px;
}
.premium-desc {
  color: #7E7E7E;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
}
.premium-img {
  border-radius: 17px;
}
.premium-inner-sec {
  padding-top: 50px;
  padding-bottom: 80px;
}

/* get-ready-sec */

.get-ready-sec {
  position: relative;
  padding-top: 100px;
  overflow: hidden;
  padding-bottom: 575px;
}
.get-ready-right {
  position: absolute;
}
.ready-img {
  width: 100%;
  padding: 3px;
}
.ready-img-middle {
  margin-top: 4rem;
}
.readi-img-last {
  margin-top: 2rem;
}
.get-ready-left-heading {
  color: #5165ac;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.6;
  margin-top: 6rem;
  margin-bottom: 3rem;
}
a.sign-btn {
  border: 1.5px solid #5165ac;
  border-radius: 12px;
  background: #5165ac;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
  padding: 13px 34px;
}
a.sign-btn:hover {
  background: #fff;
  color: #5165ac;
}
button.sign-btn {
  border: 1.5px solid #5165ac;
  border-radius: 12px;
  background: #5165ac;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  padding: 12px 34px;
}
button.sign-btn:hover {
  background: #fff;
  color: #5165ac;
}

/* worldsilder-sec */

.worldsilder-sec {
  background: #f9faff;
  padding-top: 60px;
  padding-bottom: 80px;
}
.worldslider-title.text-center {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 30px;
}
.name-person p {
  color: #4c66b1;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
.worldsilder-sec .swiper-slide.swiper-slide-next .name-person p {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
.worldsilder-sec .swiper-slide.swiper-slide-next {
  border-radius: 15.338px;
  background: #4c66b1;
}
.worldsilder-sec .swiper-slide.swiper-slide-next svg path {
  fill: #fff;
}
.worldsilder-sec .swiper-slide.swiper-slide-next .worldslider-heading {
  color: #fff;
  font-family: Poppins;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.2;
}
.worldsilder-sec .swiper-slide.swiper-slide-next .worldslider-title {
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
}
.worldsilder-sec .swiper-slide {
  border-radius: 15.338px;
  background: #d5dffb;
  padding: 22px 20px;
}
.swiper-slide .worldslider-heading {
  color: #4c66b1;
  font-family: Poppins;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.2;
  padding-top: 21px;
}
.swiper-slide .worldslider-title {
  color: #4c66b1;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
}

/* blog-sec */

.blog-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  padding-top: 16px;
  margin: 0px;
}
.blog-heading {
  color: #5165ac;
  font-family: Poppins;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.4;
  padding-top: 10px;
}
.blog-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
}
.blog-date {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}
.blog-upper.text-center {
  padding-bottom: 30px;
}
.button-inner-sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 12px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 0px;
}
.button-sec {
  background: #f9faff;
  padding: 4rem 0;
}
a.become-a-supply {
  border-radius: 12px;
  border: 1px solid #5165ac;
  color: #5165ac;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  padding: 15px 20px;
}

/* Gapp-sec */
.Gapp-sec {
  background-image: url("./image/Gapp-image.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.Gappscanner-img img {
  width: 100%;
  max-width: 45%;
}
.Gapp-social-link {
  display: flex;
  align-items: center;
  gap: 10px;
  -webkit-box-pack: center;
  padding: 12px 0px;
  -ms-flex-pack: center;
}
.Gapp-social-link a {
  width: 22%;
  margin-top: 12px;
  margin-bottom: 20px;
}
img.Gapp-img {
  width: 100%;
}
.Gapp-left {
  text-align: center;
}
.Gapp-content {
  padding-top: 4rem;
}
img.gapp-banner {
  width: 100%;
  max-width: 60%;
}
img.qr-code-img {
  width: 100%;
  max-width: 22%;
}
.Gapp-sec-upper {
  padding: 3rem 0px;
}
.Gappscanner-img {
  text-align: center;
}
.Gapp-heading {
  color: #ffffff8c;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  padding-top: 2rem;
}
.Gapp-sec-inner {
  padding-top: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 30rem;
}
.Gapp-heading-inner {
  color: #fff;
  font-size: 28px;
}

/* home-page */

/* about-page */

.image-sec {
  background-image: url("./image/about-banner-img.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -6rem;
  padding: 300px 0px;
}
.about-sec-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 800;
  line-height: 1.2;
  margin-top: 0px !important;
}
.image-title {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
}
.cards-sec-inner-left {
  border-radius: 17.5px;
  background: #fff;
  padding: 20px 15px;
}
.cards-sec-inner-left-question {
  color: #5165ac;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.4;
  padding-top: 10px;
}
ul.cards-sec-inner-left-list {
  margin: 0;
  padding: 0;
}
a.readmore {
  color: #5165AC;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
p.cards-sec-inner-left-question-answer {
  margin: 0;
}
.cards-sec-inner-right {
  border-radius: 17.5px;
  padding: 20px 15px;
  background: #fff;
}
li.cards-sec-inner-left-list-desc {
  color: #4c66b1;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  list-style-type: none;
  padding-bottom: 23px;
}
.cards-sec-inner-right-question {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  padding-top: 10px;
  line-height: 1.4;
}
li.cards-sec-inner-right-desc {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  list-style-type: none;
  padding-bottom: 23px;
}
.commitment-card{
  border-radius: 11px;
  background: #F1F3FF;
  padding: 32px 18px;
  margin-top: 2rem;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  margin-bottom: 3rem;
}
.commitment-icon {
  border-radius: 18px;
  background: #FDFDFD;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 18px;
}
.commitment-card-inner-heading {
  color: #4C66B1;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  padding-top: 14px;
}
.commitment-card-inner-title {
  color: #4C66B1;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
}
.commitment-card:hover {
  background: #4C66B1;
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-box-shadow: 0px 1.477px 39.886px 0px rgba(76, 102, 177, 0.40);
          box-shadow: 0px 1.477px 39.886px 0px rgba(76, 102, 177, 0.40);
}
.commitment-card:hover .commitment-card-inner-heading{
  color: #FFF;
}
.commitment-card:hover .commitment-card-inner-title{
  color: #fff;
}
.commitment-card:hover .commitment-icon{
  background: #EAEDF6;
}


/* blog-sec */
.blog-sec {
  padding-top: 4rem;
  padding-bottom: 5rem;
}
.about-blog-title {
  color: #242424;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
.about-blog-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  padding-top: 20px;
}
.blog-sec-inner {
  padding-top: 6rem;
}
ul.cards-list {
  margin: 0px;
  padding: 9px 0px;
}
li.blog-card-list-desc {
  color: #4c66b1;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  list-style-type: none !important;
  padding-top: 15px;
}
.blog-cards-sec-inner {
  border-radius: 30px;
  background: #fff;
  padding: 30px 35px;
}
.blog-cards-sec-inner2 {
  border-radius: 30px;
  background: #d5dffb;
  padding: 30px 35px 95px;
}
.about-blog-sec {
  padding-bottom: 5rem;
}

/* our-team */

.team-heading {
  color: #242424;
  font-size: 42px;
  font-weight: 900;
  line-height: 1.2;
}
.our-team-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}
.our-team-inner {
  padding-top: 32px;
}
.our-team {
  position: relative;
  padding: 100px 0px 250px;
}
.our-team-inner::before {
  position: absolute;
  content: "";
  background-image: url("./image/as-i-see-before.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 80%;
  height: 69%;
  bottom: 4px;
  right: 155px;
}

/* as-i-see-sec */

.as-i-see-sec {
  background: #f9faff;
  padding-bottom: 75px;
  padding-top: 75px;
}
.as-i-see-sec-inner img {
  width: 14%;
  margin-left: 18px;
  -webkit-box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
}
.as-i-see-sec-img {
  padding-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.commitment-sec {
  padding: 75px 0px;
}

/* our-blog-sec */
.See-more {
  margin-top: 3rem;
}
.blog-sec-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 800;
  line-height: 1.2;
  margin-top: 0px !important;
}
.blog-image-sec {
  background-image: url("./image/our-blog-bg.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 16rem 0rem;
  margin-top: -12rem;
}
.our-blog-categories {
  border-radius: 30px;
  background: #fff;
  -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
  padding: 24px 34px;
}
.our-blog-bottom {
  padding-top: 80px;
}
.our-blog-categories-heading {
  color: #5165ac;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 20px;
}
ul.blog-categories-list {
  padding: 0rem !important;
}
.blog-categories-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
a.blog-categories-list-name {
  color: #7e7e7eb3;
  font-family: Poppins;
  list-style-type: none !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 22px;
}
.business-blog-card {
  border-radius: 30px;
  background: #fff;
  -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 3rem;
  padding: 30px 0px;
}
.business-blog-card-right-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.business-blog-card-right-date {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.business-blog-card-right-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.business-blog-card-right-heading {
  color: #5165ac;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.2;
}
.business-blog-card-right-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
a.business-blog-card-right-link {
  color: #5165ac;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
}
.our-blog-search {
  margin-top: 4rem;
}
.our-blog input#gsearch {
  border-radius: 12px;
  background: #f9f9f9;
  border: unset;
  padding: 14px 50px 14px 16px;
  width: 100%;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  display: block;
}
.our-blog-inner-right {
  margin-left: 4rem;
}

/* Business-page */

/* cbanner-sec */

.cbanner-sec {
  padding-top: 250px;
  padding-bottom: 150px;
  overflow: hidden;
  height: 100%;
  position: relative;
}
.banner-mobile-video{
  display: none;
}
video.banner-video {
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
}
.video-container {
  overflow: hidden;
}
.banner-thumbnail {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
}
.cbanner-sec-inner-heading {
  color: #fff;
  font-size: 52px;
  font-weight: 800;
  line-height: 1.2;
}
.partner-names {
  margin-top: 4rem;
}
.cbanner-sec-inner-title {
  color: #3A3A3A;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.4;
  text-transform: uppercase;
  padding: 16px 0px 10px;
}
.cbanner-sec-inner img {
  width: 45%;
}
.cbanner-sec-inner {
  border-radius: 50%;
  background: #ffffff8a;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  width: 350px;
  margin: auto;
  padding: 56px 32px;
  height: 350px;
}
.cbanner-sec .glow-blue-btn a {
  border-radius: 12px;
  background: #5165ac;
  color: #fff;
  padding: 12px 15px;
  font-size: 12px;
  font-weight: 500;
  transition: 0.5s;
  line-height: 1.2;
  text-transform: uppercase;
}
.video-control:not(.playing) .video-control-pause,
.video-control.playing .video-control-play {
	display: none;
}
.cwhite-btn a {
  border-radius: 10px;
  background: #fff;
  padding: 20px 32px;
  color: #4a67ab;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
}
.our-partner {
  padding: 50px 0px 50px;
}
/* what-vendio-sec */

.what-vendior {
  padding: 75px 0px;
}
.what-vendior-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
.what-vendior-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.what-vendior-cards-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  border-radius: 10px;
  background: #f5f8ff;
  padding: 26px 20px;
}
.what-vendior-cards-desc:hover {
  background: #5165ac;
  color: #fff;
}
.what-vendior-right {
  margin-left: 4rem;
}
.what-vendior-left-cards {
  margin-top: 3rem;
}

/* cslider-sec */

.cslider-sec {
  padding-top: 72px;
  padding-bottom: 72px;
  background: #f9faff;
}
.cslider-uper-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
.cslider-uper-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.getstartedBtn{
  padding-top: 10px;
}
.cslider-uper {
  padding-bottom: 4rem;
}
.video-sec {
  overflow: hidden;
  width: 100%;
  padding-bottom: 72px;
}

button.video-control {
  background: transparent;
  border: none;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 82%;
  left: 50%;
  transform: translate(-50%, 0);
}
/* download-sec */

.download-sec {
  background: #4a67ab;
}
.download-sec-heading {
  color: #ffffff;
  font-size: 42px;
  font-weight: 600;
  line-height: 56px;
}
.download-sec-title {
  color: #ffffffc4;
  font-size: 21px;
  font-weight: 500;
  line-height: 31px;
  padding-top: 10px;
}
.download-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  padding-top: 4rem;
}
img.download-img-left {
  width: 100%;
  max-width: 70%;
}

/* choose-vendior */

.choose-vendior {
  padding-top: 72px;
  padding-bottom: 72px;
}
.choose-vendior-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
.choose-vendior-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
}
.choose-vendior-card-icon {
  border-radius: 20px;
  background: #e2e7fc;
  padding: 5px 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.vendior-card-desc h4 {
  color: #5165ac;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}
.choose-vendior-card {
  border-radius: 18px;
  background: #f9faff;
  padding: 18px 22px;
  margin-top: 1rem;
}
.choose-vendior-card:hover {
  background: #5165ac;
}
.choose-vendior-card:hover .vendior-card-desc h4 {
  color: #fff;
}
.choose-vendior-card:hover .choose-vendior-card-desc {
  color: #fff;
}
.choose-vendior-card:hover .choose-vendior-card-icon {
  background: #fff;
}
.choose-img-mobile {
  display: none;
}
.card-choose-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 22px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.choose-vendior-card-desc {
  color: #5165AC;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}

/* contact-sec */
.Contactform-sec {
  padding: 75px 0px;
}
label.tc-desc {
  color: #8d8d8d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  padding-top: 10px;
}
span.termsandcondition {
  color: #5165ac;
  font-weight: 600;
}

.Contactform-sec-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
textarea {
  resize: vertical;
  border: 0 !important;
  border-bottom: 1px solid #dcdcdc !important;
  color: #8d8d8d;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2; /* 133.333% */
}
.Contactform-sec-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}
.Contactform-inner-left {
  background-color: #5165AC;
  padding: 50px;
  border-radius: 20px;
  }
.Contactform-inner-left-heading {
  color: #fff;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 25px;
}
.Contactform-inner-left-title {
  color: #ffffff80;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 4rem;
}
.Contactform-inner-title {
  color: #ffffffad;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
}
.Contactform-inner-right {
  margin-top: 4rem;
}
.form-control {
  border-radius: 0px !important;
  border: none !important;
  border-bottom: 1px solid #dcdcdc !important;
}
.Contactform-sec label.form-label {
  color: #8d8d8d;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
}
form.contact-form {
  padding-bottom: 2rem;
}

/* slider-sec */
.vendior-support-heading {
  color: #5165ac;
  text-align: center;
  font-size: 52px;
  font-weight: 900;
  line-height: 1.2;
}
.thank-you-contact {
  color: #000;
}
.vendior-support-title {
  color: #7e7e7e;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
}
.our-partner-sec {
  padding-bottom: 72px;
}
.vendior-support-inner-upper {
  padding-bottom: 4rem;
}
.vendior-support-contact {
  color: #7e7e7e;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.2;
  padding-top: 6rem;
}

/* service-page */

.service-sec {
  background-image: url("./image/service-bg-img.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 24rem;
  margin-top: -10rem;
}
.service-sec-heading {
  color: #5165ac;
  font-size: 68px;
  font-weight: 800;
  line-height: 1.2;
  margin-top: 4rem;
}
.service-main-title {
  color: #242424;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.2;
}
.service-main-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}
li.service-list-name {
  padding-bottom: 18px;
}
.service-sec-inner {
  margin-top: 4rem;
}
img.service-img {
  width: 67%;
}
.partner-img-inner img {
  width: 83%;
}
.logoWidth {
  width: 55%;
  margin: auto;
}

.blue-bg {
  background: #f9faff;
  padding: 40px 0px;
  margin: 72px 0rem;
}
.vendior-event-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}
.vendior-support .choose-vendior-card-icon {
  border-radius: 20px;
  background: #e2e7fc;
  padding: 14px 22px;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
.vendior-support-sec {
  background-color: #f9faff;
  padding: 80px 0px;
}
.support-vendior-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #f9faff;
  gap: 20px;
  border-radius: 30px;
  border: 1.5px solid rgba(81, 101, 172, 0.15);
  background: #f9faff;
  margin-bottom: 3rem;
  padding: 24px 20px 9px;
}
.support-vendior-card:hover {
  background: -webkit-gradient(linear, left bottom, left top, from(#5165ac), to(#5165ac)),
    rgba(0, 0, 0, 0.5);
  background: -o-linear-gradient(bottom, #5165ac 0%, #5165ac 100%),
    rgba(0, 0, 0, 0.5);
  background: linear-gradient(0deg, #5165ac 0%, #5165ac 100%),
    rgba(0, 0, 0, 0.5);
}
.support-vendior-card:hover h4.vendior-support-heading {
  color: #fff;
}
h4.vendior-support-heading {
  color: #5165ac;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;
}

/* partner-sec */

.our-partner-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
.our-patner-inner {
  padding-top: 4rem;
}

/* cabout-sec */

.our-value-heading {
  color: #242424;
  font-size: 62px;
  font-weight: 900;
  line-height: 1.2;
}
.our-value-card-icon {
  border-radius: 20px;
  background: #e2e7fc;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 20px;
  margin-bottom: 20px;
}
.Inclusivity-heading {
  color: #5165ac;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
}
.our-value-sec {
  padding-top: 100px;
}
.cabout-our-team {
  position: relative;
  padding-bottom: 300px;
  padding-top: 50px;
}
.Inclusivity-title {
  color: #5165ac;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}
.our-value {
  background: #f9faff;
  padding-top: 3rem;
  padding-bottom: 7rem;
}
.our-value-card {
  border-radius: 18px;
  background: #fff;
  padding: 20px 22px;
}
.our-value-upper {
  padding-bottom: 36px;
}

/* blog-detail */
.Blog-detail-image-sec {
  background-image: url("./image/blog-detail-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -6rem;
  padding: 300px 0px;
}
.Blog-detai-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
  margin-top: -3rem;
}
.blog-detail-sec-inner {
  margin-top: -6rem;
}
.blog-detail-bottom-lists-name {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  list-style-type: none;
  padding-top: 25px;
}
.blog-detail-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.service-sec-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 800;
  line-height: 1.2;
  margin-top: 9rem;
  padding-bottom: 4rem;
}
.blog-deatils-card {
  padding-bottom: 4rem;
}
.ptop {
  padding-top: 4rem;
}
.team-vendior {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

/* Sustainability-sec */

.Sustainability-image-sec {
  background-image: url("./image/sustaintable-sec-g.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -6rem;
  padding: 300px 0px;
}
.key-features-heading {
  color: #5165ac;
  font-family: Lato;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 3rem;
}
.key-features-sec {
  background: #F9FAFF;
  padding-bottom: 80px;
  padding-top: 40px;
  margin-top: 4rem;
}
.key-features-inner {
  margin-top: 5rem;
}
.our-focus-card {
  border-radius: 18px;
  background: #f9faff;
  margin-bottom: 2rem;
  padding: 20px;
}
.our-focus-card-icon {
  border-radius: 20px;
  background: #e2e7fc;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px;
}
.our-focus {
  padding-top: 5rem;
}
.our-focus-inner {
  padding-top: 2rem;
}
.our-focus-card-desc h4 {
  color: #5165ac;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.2;
}
.our-focus-card-desc p {
  color: #5165ac;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.2;
}
.embrace-sec-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
.embrace-sec-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.embrace-sec {
  padding-top: 6rem;
}
.embrace-sec-inner-img {
  padding-top: 2rem;
}

/* Referfriend-sec */

.Referfriend-image-sec {
  background-image: url("./image/referfriend-bg-img.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -6rem;
  padding: 300px 0px;
}
.earn-extra-money-heading svg {
  margin: 0px 6px;
}
.earn-extra-money-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
  padding-top: 3rem;
  padding-bottom: 1rem;
}
.earn-extra-money-title {
  color: #242424;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.5;
}
.rbusiness-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 2rem;
}
li.extra-money-list-name {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  padding-top: 16px;
}
.join-vendior {
  background: #f9faff;
  padding-bottom: 5rem;
}
.extra-money-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.6;
  padding-top: 42px;
}

/* accordion-sec */

.faq-heading {
  color: #5165ac;
  font-family: Lato;
  font-size: 52px;
  font-weight: 900;
  line-height: 1.2;
}
.faq-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
}
.accordion-header-name {
  color: #242424;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}
.accordion-section {
  border-bottom: 1px solid #dcdcdc;
}
.accordion {
  padding-top: 2rem;
}
.accordion-sec {
  padding: 4rem 0rem 6rem;
}
.accordion-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 22px;
  padding-top: 2rem;
}
.accordion-body {
  color: #7e7e7e;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}
.accordion-header-sign {
  background: #e2e7fc;
  border-radius: 50%;
  /* padding: 1px 8px; */
  width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;
  text-align: center;
  cursor: pointer;
}

/* referbusiness-sec */

.Referbusiness-image-sec {
  background-image: url("./image/referbusiness.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -6rem;
  padding: 300px 0px;
}
.earn-extra-money {
  padding-bottom: 4rem;
}
.Sustainability-new-title {
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.friend-info-inner {
  width: 62%;
  border-radius: 12px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
  padding: 54px 25px;
  margin: auto;
}
.friend-info {
  background: #f9faff;
  padding: 72px 0px;
}
.friend-info-inner-heading {
  color: #242424;
  font-family: Lato;
  font-size: 28px;
  font-weight: 900;
  line-height: 44px;
  padding-bottom: 3rem;
}
.contact-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 3rem;
}
#close-route {
  border: none;
}
/* become-supply */
.Bsuppliy-sec {
  background-image: url("./image/dj.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -6rem;
  padding: 300px 0px;
}
.bsupply-middle-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}
.bsupply-middle-title {
  color: #7e7e7e;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.2;
}
.bsupply-middle.text-center {
  padding: 5rem;
}
.bsupply-sec-inner {
  width: 80%;
  border-radius: 12px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
  padding: 54px 25px;
  margin: auto;
  margin-bottom: 9rem;
}
label.form-label {
  color: #8d8d8d;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.yes-no {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
}
.yes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.no {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  gap: 3px;
}
.submit-btn {
  border-radius: 15px !important;
  background: #5165ac !important;
  padding: 10px 23px !important;
  float: right;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12) !important;
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12) !important;
  margin-right: 6rem;
}
.eco-friendly-inner-input {
  position: relative;
  top: 2px;
}
.eco-friendly-inner-lable {
  color: #8d8d8d;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
}
.bsupply-sec {
  padding-bottom: 5rem;
}

/* gpackage-sec */

.Gpackage-image {
  background-image: url("./image/Growth-package-img.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -6rem;
  padding: 300px 0px;
}
label.agree-msg {
  color: #8d8d8d;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.Gpackage-sec {
  margin-top: 3.5rem;
  padding-top: 3rem;
}
.Gpackage-inner {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

/*End popup styles*/

.Ourpackage-image {
  background-image: url("./image/our-package-img.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -6rem;
  padding: 230px 0px;
}
.starter-package {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 40px;
  background: #7790d9;
  padding: 40px 42px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.growth-package{
  position: relative;
}
.growth-package:before{
  position: absolute;
  content: "";
  background-image: url("./image/ribbon.png");
  background-repeat: no-repeat;
    background-size: 100%;
    top: -4px;
    width: 121px;
    right: -2px;
    z-index: 1;
    height: 150px;

}

.starter-package:hover {
  background: #4c66b1;
}
.sub-packages {
  margin: 45px 0px;
}
.starter-package h4 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 25px;
}
.package-list ul {
  list-style: none;
  padding: 0;
}
.package-list ul li {
  position: relative;
  padding-left: 25px;
  padding-bottom: 15px;
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.package-list ul li::before {
  position: absolute;
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url("./image/right-icon.png");
  background-size: cover;
  top: 6px;
  left: 0;
}
.package-btn {
  text-align: center;
  margin-top: 40px;
}
.package-btn:hover {
  cursor: pointer;
}
.package-btn {
  border-radius: 12px;
  background: #fff;
  border: 1px solid #4c66b1;
  -webkit-box-shadow: 0px 20.516px 51.291px 0px rgba(62, 58, 58, 0.2);
          box-shadow: 0px 20.516px 51.291px 0px rgba(62, 58, 58, 0.2);
  text-decoration: none;
  padding: 10px 35px;
  color: #4c66b1;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.package-btn:hover {
  border: 1px solid #fff;
  color: #fff;
  background: #4c66b1;
}

/* tab-sec */
.info {
  display: none;
  border-radius: 20px;
  border: 1.5px solid #fff;
  background: #fff;
}
.item {
  float: left;
  width: 5%;
  border-radius: 20px;
  border: 1.5px solid #fff;
  position: relative;
  margin-left: 1rem;
}
.border-faq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.info.active-info {
  display: block;
  width: 100%;
}
span.icon-name {
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  display: block;
  color: #fff;
  font-size: 30px;
  font-weight: 900;
  line-height: 1.2;
  white-space: nowrap;
  position: absolute;
  bottom: 40%;
  left: -70px;
}
.item-content-list li {
  padding-bottom: 1rem;
  color: #4c66b1;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  list-style-type: none;
}
.item.active {
  background: white;
  border-radius: 15px 0px 0px 15px;
}
.item.active span.icon-name {
  color: #5165ac;
}
.item-content-list {
  padding: 2rem 2rem 0rem 1rem;
}
.info.active-info {
  display: block;
  width: 100%;
  border-radius: 0px 20px 20px 0px;
}

/* bstepper-sec */

.close {
  border-radius: 50%;
  background: #5165ac24;
  padding: 7px 7px;
}
.Bstepper-sec-uper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 3rem;
  margin-top: 1rem;
  gap: 30rem;
}
.stepper-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
img.stepper-logo {
  width: 100%;
  max-width: 18%;
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  visibility: hidden !important;
}
.progress {
  border-radius: 20px !important;
}
.progress-bar {
  border-radius: 20px !important;
  background: #5165ac !important;
}
.step1-heading {
  color: #5165ac;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 5px;
}
.step1-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 2px;
}
.step3-inner .step-2-list ul li {
  margin-bottom: 32px;
  width: calc(100% / 5);
}
.step1.text-center {
  margin-top: 4rem;
}
img.step-1-img {
  width: 100%;
  max-width: 10%;
  padding-bottom: 2rem;
}
button.next-btn {
  border-radius: 16px;
  background: #5165ac;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 10px 31px;
  margin-right: 7rem;
}
button.prev-btn {
  border-radius: 15px;
  border: 1px solid #5165ac;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  background: #fff !important;
  padding: 10px 31px;
  color: #5165ac !important;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin-left: 7rem;
}
.step-check-box {
  background-color: #fff;
  border: 2px solid #cddff9;
  border-radius: 6.25rem;
  padding: 0.8rem 1.5rem;
}
.step-2-list {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
}
.step-2-list ul {
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.step-2-list ul li {
  margin-bottom: 10px;
  width: calc(100% / 8);
}
.form-search-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}
button.search-btn-inner {
  border-radius: 20px;
  background: #5165ac;
  border: 0px;
  padding: 10px 22px;
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
}
.form-search {
  padding-top: 2rem;
}
.last-step {
  padding-bottom: 10rem;
}
.organise-btn {
  margin-bottom: 4rem;
  margin-left: -9rem;
}
.step-inner-checkbox .checkbox-msg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 9px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.step-inner-checkbox label.checkbox-desc {
  color: #242424;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}
.date-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 20px;
  padding-top: 2rem;
  width: 50%;
  margin: 0 auto;
}
.date,
.time {
  width: 50%;
}
.date-time label {
  color: #242424;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  margin-right: 10px;
}

.date-time input {
  width: 100%;
  padding: 0.5rem;
}
input#birthday {
  border-radius: 12px;
  background: #f9faff;
  border: 0;
  padding: 13px 60px;
}
.form-search-inner input#gsearch {
  border-radius: 20px;
  background: #f9f9f9;
  border: 0px;
  padding: 14px 35px;
}
.Btepper-sec-card.step-2-list ul li input {
  display: none;
}
.Btepper-sec-card.step-2-list ul li input:checked ~ label {
  position: relative;
}
.Btepper-sec-card.step-2-list ul li input:checked ~ label:before {
  content: "\2713";
  font-size: 0;
  position: absolute;
  bottom: 0;
  left: 64%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='53' height='58' viewBox='0 0 53 58' fill='none'%3E%3Ccircle cx='29' cy='29' r='23' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.4375 29C5.4375 15.9862 15.9862 5.4375 29 5.4375C42.0138 5.4375 52.5625 15.9862 52.5625 29C52.5625 42.0138 42.0138 52.5625 29 52.5625C15.9862 52.5625 5.4375 42.0138 5.4375 29ZM37.7242 24.6162C37.8692 24.423 37.9741 24.2027 38.0327 23.9684C38.0914 23.734 38.1026 23.4903 38.0658 23.2516C38.0289 23.0129 37.9447 22.7839 37.8181 22.5782C37.6914 22.3725 37.525 22.1941 37.3285 22.0537C37.1319 21.9132 36.9093 21.8134 36.6737 21.7602C36.438 21.707 36.1941 21.7015 35.9563 21.7439C35.7185 21.7863 35.4916 21.8758 35.2888 22.0072C35.0861 22.1385 34.9117 22.3091 34.7758 22.5088L26.9555 33.4563L23.0308 29.5317C22.6872 29.2115 22.2328 29.0372 21.7632 29.0455C21.2937 29.0538 20.8457 29.244 20.5136 29.5761C20.1815 29.9082 19.9913 30.3562 19.983 30.8257C19.9747 31.2953 20.149 31.7497 20.4692 32.0933L25.9067 37.5308C26.0927 37.7168 26.317 37.8599 26.564 37.9505C26.8109 38.041 27.0746 38.0767 27.3368 38.0551C27.5989 38.0335 27.8532 37.9551 28.082 37.8253C28.3108 37.6955 28.5086 37.5175 28.6617 37.3037L37.7242 24.6162Z' fill='%235165AC'/%3E%3C/svg%3E");
  height: 30px;
  width: 30px;
  background-size: 100%;
}
.step-2-list input[type="checkbox"] {
  display: none;
}
.step-2-list label {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.step-2-list label img {
  border: 1px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
  height: 6rem;
  width: 6rem;
  -webkit-transition: border-color 0.3s ease-in-out;
  -o-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
}
.step-2-list input[type="checkbox"]:checked + label img {
  border-color: #2196f3;
}
.step-2-list input[type="checkbox"]:checked + label::before {
  content: "\2713";
  font-size: 0;
  position: absolute;
  bottom: 0;
  left: 64%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='53' height='58' viewBox='0 0 53 58' fill='none'%3E%3Ccircle cx='29' cy='29' r='23' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.4375 29C5.4375 15.9862 15.9862 5.4375 29 5.4375C42.0138 5.4375 52.5625 15.9862 52.5625 29C52.5625 42.0138 42.0138 52.5625 29 52.5625C15.9862 52.5625 5.4375 42.0138 5.4375 29ZM37.7242 24.6162C37.8692 24.423 37.9741 24.2027 38.0327 23.9684C38.0914 23.734 38.1026 23.4903 38.0658 23.2516C38.0289 23.0129 37.9447 22.7839 37.8181 22.5782C37.6914 22.3725 37.525 22.1941 37.3285 22.0537C37.1319 21.9132 36.9093 21.8134 36.6737 21.7602C36.438 21.707 36.1941 21.7015 35.9563 21.7439C35.7185 21.7863 35.4916 21.8758 35.2888 22.0072C35.0861 22.1385 34.9117 22.3091 34.7758 22.5088L26.9555 33.4563L23.0308 29.5317C22.6872 29.2115 22.2328 29.0372 21.7632 29.0455C21.2937 29.0538 20.8457 29.244 20.5136 29.5761C20.1815 29.9082 19.9913 30.3562 19.983 30.8257C19.9747 31.2953 20.149 31.7497 20.4692 32.0933L25.9067 37.5308C26.0927 37.7168 26.317 37.8599 26.564 37.9505C26.8109 38.041 27.0746 38.0767 27.3368 38.0551C27.5989 38.0335 27.8532 37.9551 28.082 37.8253C28.3108 37.6955 28.5086 37.5175 28.6617 37.3037L37.7242 24.6162Z' fill='%235165AC'/%3E%3C/svg%3E");
  height: 30px;
  width: 30px;
  background-size: 100%;
}
.step2-list-desc- {
  color: #242424;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.step2-inner {
  padding: 3rem 0 6rem;
}
.step-upper {
  padding-top: 3rem;
}
.step-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.request-content {
  color: #8d8d8d;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
  line-height: 1.2;
  padding: 20px 0px 5px;
}
.sustainable-step-heading {
  color: #5165ac;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 1rem;
}
p.provide-content {
  color: #8d8d8d;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  padding: 20px 0px 6px;
  line-height: 1.2;
  margin: 0;
}
.step-form {
  margin-bottom: 10rem;
}
.step3-inner {
  padding-bottom: 7rem;
}
.step-svg {
  margin-left: -3.5rem;
  border-left: 1px solid #d9d9d9;
  padding-left: 7px;
}
.step-bottom.text-center {
  width: 50%;
  margin: auto;
  padding: 10px 0px 12px;
}
.step-bottom.text-center input {
  border-radius: 12px;
  background: #f9faff;
  border: 1px solid #f9faff;
  padding: 18px 10px;
  /* padding: 20px 0px 16px 20px; */
  margin-top: 2rem;
  width: 100%;
}
.step-bottom5 input {
  border-radius: 12px;
  background: #f9faff;
  border: 1px solid #f9faff;
  margin-top: 2rem;
  width: 10%;
  padding: 12px 9px;
}
.step-bottom5 label {
  color: #242424;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  margin-right: 10px;
}
.yes-no-content {
  color: #242424;
  font-family: Poppins;
  font-size: 14px;
  padding-bottom: 5px;
  font-weight: 600;
  line-height: 1.2;
}
.css-13cymwt-control {
  border: none !important ;
  border-radius: 12px !important;
  background: #f9faff !important;
}
.css-1fdsijx-ValueContainer {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
  padding: 2px 34px !important;
}

.select-step .yes-no {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.select-step {
  padding-top: 22px;
}
select,
input[placeholder] {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
#subscripe [placeholder] {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}
.bsupply-sec-inner label.checkbox-desc {
  color: #8d8d8d;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.step6 .step-bottom6.text-center label {
  border-radius: 12px 0px 0px 12px;
  background: #eaeaea;
  padding: 10px 0px;
}
.form-select {
  border-bottom: 1px solid #ced4da !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  color: #8d8d8d !important;
  font-family: Poppins;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 1.2;
}
.Btepper-sec-card.step-2-list ul li {
  margin-bottom: 32px;
  width: calc(100% / 5);
}
.Btepper-sec-card.step-2-list ul {
  list-style: none;
  padding: 0 8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}
.checkbox-desc-ecofriendly {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.ecofriendly-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* Thank-you-sec */

.Thank-you-sec {
  background: #5165ac;
  height: 100vh;
  padding: 50px 0px;
}
.bthank-social-link img {
  width: 15%;
}
.Bthank-bottom-title {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 3rem;
}
.bthank-store {
  padding-left: 1rem;
}
.thank-you-heading {
  color: #fff;
  font-size: 72px;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  padding-top: 5rem;
}
.thank-you-title {
  color: #ffffffa8;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 18px;
}
.thank-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.vendior-team {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.video-download {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 4rem;
}

.video-download iframe {
  width: 100%;
  height: 500px;
}

/* conditions-pages */

section.privacy {
  padding: 40px 0px;
}
.privacy-heading {
  padding-bottom: 30px;
}
.privacy-heading h5 {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}
.privacy-heading h1 {
  color: #5165ac;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
}
.privacy-content {
  padding-bottom: 15px;
}
.privacy-title {
  color: #5165ac;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
}
.privacy-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
}
.privacy-desc span {
  color: #5165ac;
}
.privacy-desc a {
  color: #5165ac;
  text-decoration: none;
}
.privacy-sub-title {
  color: #242424;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 12px;
  text-transform: lowercase;
}
.privacy-content h3 {
  font-family: Poppins;
  color: #242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.72px;
}
.privacy-list .list-item {
  margin-bottom: 5px;
}
.privacy-main-desc {
  margin-bottom: 30px;
}

.cookie-list ul {
  list-style-type: none;
  padding: 0;
  border-radius: 25px;
  border: 0.8px solid #7e7e7e;
  background: #f9faff;
  padding: 20px;
}
.cookie-list .list-item {
  margin-bottom: 0px;
}

ol.terms-list {
  padding-left: 15px;
}
.dsiclamer-list li {
  margin-bottom: 5px;
}
.privacy-faq .accordion-button::after {
  height: 25px;
  width: 25px;
  display: block;
  position: absolute;
  top: 15px;
  right: 14px;
  background-color: #e2e7fc;
  border-radius: 50%;
  background-size: 17px;
  background-position: 40% 60%;
  background-repeat: no-repeat;
}
.privacy-faq h2.accordion-header {
  padding: 0;
}
.privacy-faq li {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
}
.privacy-faq .accordion-item {
  border: none;
  border-bottom: 1px solid #000;
}
.privacy-faq .accordion-button {
  color: #242424;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}
.privacy-faq .accordion-button:not(.collapsed) {
  color: #242424 !important;
  background-color: transparent !important;
}
.privacy-faq .accordion-button:focus {
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
}

/* pop-up-sec */
.popup-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.popup-link a {
  background: #333;
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  margin: 20px;
  text-decoration: none;
}

.popup-container {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
          transform: scale(1.3);
  position: fixed;
  z-index: 1000000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 17, 17, 0.61);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pop-sign-up {
  margin: 2rem 0 1rem;
}
.popup-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 476px;
}
.popup-content p {
  font-size: 17px;
  padding: 10px;
  line-height: 20px;
}
.popup-content a.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  background: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
}
.sign-up-heading {
  color: #5165ac;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  padding-top: 20px;
}
.sign-up-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
}

.popup-content a.close:hover {
  color: #333;
}

.popup-content span:hover,
.popup-content span:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.popup-container:target {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
.pop-up-content-inner img {
  width: 46%;
}

/* resouce-page */

.resource-image-sec {
  background-image: url("./image/resource-main-img.webp");
}
.reesource-main-desc {
  color: #7e7e7e;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.resorce-sub-content {
  margin-bottom: 30px;
}
.resource-sub-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.resource-sub-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}
.resorce-sub-list li {
  margin-bottom: 10px;
}
.resource-content {
  margin-top: 20px;
}
.resource-image-first {
  background-image: url("image/resource-first-img.webp");
}
.resource-image-second {
  background-image: url("image/resource-second-img.webp");
}
.resource-image-third {
  background-image: url("image/resource-third-img.webp");
}
.resource-image-fourth {
  background-image: url("image/resource-fourth-img.webp");
}
.resource-image-fifth {
  background-image: url("image/resource-fifth-img.webp");
}
.resource-image-sixth {
  background-image: url("image/resource-sixth-img.webp");
}

/* Blog-inner-page */
.blog-inner-image-sec {
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 200px 0px 0px;
}
.blog-imag-one {
  background-image: url("image/blog-inner1-bg.webp");
}
.blog-inner-page-heading {
  color: #5165ac;
  font-size: 42px;
  padding-top: 17rem;
  font-weight: 600;
  line-height: 1.2;
}
.building-heading {
  color: #5165ac;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.blog-building-date {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.detail-inner-desc {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
}
.blog-inner-bottom {
  padding: 72px 0px;
}
.blog-inner-lists-heading {
  color: #5165ac;
  font-family: Lato;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
}
.blog-inner-lists-title {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
}
.vendior-date {
  color: #5165ac;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.2;
  padding-top: 40px;
}
.blog-inner-lists {
  padding-top: 12px;
}
.blog-imag-second {
  background-image: url("image/blog-inner2-bg.webp");
}
.blog-imag-third {
  background-image: url("image/blog-inner3-bg.webp");
}
.blog-imag-four {
  background-image: url("image/blog-inner4-bg.webp");
}
.blog-imag-fifth {
  background-image: url("image/blog-inner5-bg.webp");
}
.blog-imag-seven {
  background-image: url("image/blog-inner7-bg.webp");
}
.blog-imag-six {
  background-image: url("image/Blog_six_img.webp");
}
.blog-imag-eight {
  background-image: url("image/blog_eight_img.webp");
}
.blog-imag-nine {
  background-image: url("image/blog_nine_img.webp");
}
.blog-imag-ten {
  background-image: url("image/blog_ten_img.webp");
}
.blog-imag-eleven {
  background-image: url("image/blog-inner11-bg.webp");
}
.blog-imag-twelve {
  background-image: url("image/blog-inner12-bg.webp");
}
span.blog-inner-heading {
  color: #7e7e7e;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
}
.blog-imag-thirteen {
  background-image: url("image/Blog_thirteen_img.webp");
}
.blog-imag-fourteen {
  background-image: url("image/Blog_Fourteen_img.webp");
}
.blog-imag-fifteen {
  background-image: url("image/Blog_fifteen_img.webp");
}
.blog-imag-sixteen {
  background-image: url("image/Blog_sixteen_img.webp");
}
.blog-imag-seeventeen {
  background-image: url("image/Blog_seveenteen_img.webp");
}
.blog-imag-eighteen {
  background-image: url("image/Blog_eighteen_img.webp");
}

.blog-imag-nineteen {
  background-image: url("image/Blog_nineteen_img.webp");
}
.blog-imag-twenty {
  background-image: url("image/blog_twenty_img.webp");
}
.blog-imag-twentyOne {
  background-image: url("image/blog_twentyOne_img.webp");
}
.blog-imag-twentyTwo {
  background-image: url("image/blog_twentyTwo_img.webp");
}
.blog-imag-twentyThree {
  background-image: url("image/blog_twentyThree_img.webp");
}
.blog-imag-twentyFour {
  background-image: url("image/blog_twentyFour_img.webp");
}
.blog-imag-twentyFive {
  background-image: url("image/blog_twentyFive_img.webp");
}
.mob-img {
  display: none;
}
.des-img {
  display: block;
}

/* / error-page-sec / */
.error-page {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-image: url(image/error-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
}
.error-image img {
  width: 100%;
}
.error-text h4 {
  color: #242424;
  text-align: center;
  font-family: Lato;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 50px;
  margin-bottom: 20px;
}
.error-text p {
  color: #7E7E7E;
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 530px;
  margin: auto;
  margin-bottom: 30px;
}
button.error-button {
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 20px;
  background: #5165AC;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 15px 30px;
  border: none;
  -webkit-transition: 0.5s ;
  -o-transition: 0.5s ;
  transition: 0.5s ;
}
button.error-button:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.error-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.error-button a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

/* sidemap-map-sec */

.sitemap-top {
  background: #4961B0;
}
img.sidemao-logo {
  width: 50%;
}
.sitemap-top-inner {
  text-align: center;
}
.sitemap-top {
  padding: 30px 0px;
}
.vendior-btn {
  border-radius: 15px;
  border: 2px solid #4961B0;
  background: #4961B0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  color: #FFF;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.2;
  padding: 12px 35px;
  margin: 3rem auto;
  position: relative;
}
.coustom-Business {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  gap: 1rem;
}
.coustom {
  width: 50%;
  text-align: center;
}
.Business {
  width: 50%;
  text-align: center;
}
.coustom-Business-link {
  color: #4961B0;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  border-radius: 18px;
  border: 2px solid #4961B0;
  text-transform: capitalize;
  padding: 16px 46px;
  position: relative;
}
.coustom-Business-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 8rem;
  gap: 2rem;
  
}
.coustom-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  gap: 0.5rem;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
      
      justify-content: flex-end;
  width: 100%;
  margin-top: 3rem;
}
.sidemap-blog-sec {
  width: calc(100% / 6);
}
.business-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  gap: 0.5rem;
  width: 100%;
  margin-top: 3rem;
}
.coustom-business-link {
  border-radius: 17.176px;
  border: 1.227px solid #4961B0;
  background: #F4F4F4;
  color: #000;
  padding: 10px 5px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  text-transform: capitalize;
  white-space: nowrap;
  width: 100%;
  display: block;
}
.sidemap-blog-sec-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.sidemap-blog-sec-inner-link {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: capitalize;
  text-align: center;
  padding: 8px 10px;
  border: 1px solid #0000002e;
  border-radius: 10px;
  margin-top: 12px;
}


/* according-sec */
.accountdelete-name {
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  padding-top: 22px;
}
.account-delete-heading {
  color: #5165ac;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
}
.accountdelete-title {
  color: #343851;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
}
img#bloImg {
  max-height: 414px;
}
.values-item {
  color: #7e7e7e;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 25px;
  margin-bottom: 30px;
}
.according-main-title {
  color: #5165ac;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  padding-top: 38px;
}
img.app-logo {
  width: 22%;
  padding-bottom: 2rem;
}
.app-footer {
  margin-top: 3rem;
}

/* unique-together */

.unique-together-sec {
  padding: 50px 0px 80px;
  background: #F9FAFF;
}
.unique-event{
  color: #5165AC;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: capitalize;
}
.unique-event.text-center {
  padding-bottom: 5rem;
}
.unique-together-title {
  color: #5165AC;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}
.unique-together-left img {
  width: 80%;
}
.unique-together-desc {
  color: #7E7E7E;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}
/* our-patner */

.our-partner .container{
  max-width: 1100px;
}
.partner-img img {
  width: 50%;
}
.partner-img {
  text-align: center;
}
.react-multi-carousel-track {
  align-items: center;
}
.service-partner-sec .partner-img img {
  width: 40%;
}


/* responsive-sec */

@media only screen and (min-width: 1920px) {
  .Blog-detai-heading {
    margin-top: 6rem;
  }
  .service-sec-heading {
    margin-top: 17rem;
  }
  .blog-inner-page-heading {
    padding-top: 30rem;
  }
  .about-blog-sec {
    margin-top: 2rem;
  }
  .margin-top {
    margin-top: 6rem;
  }
  .video-download {
    width: 30% !important;
  }
  .as-i-see {
    margin-top: -4rem;
  }
  video.banner-video {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    object-fit: cover;
  }
}
 @media only screen and (min-width: 2500px) {
  .service-sec-heading {
    margin-top: 30rem;
  }
  .blog-inner-page-heading {
    padding-top: 42rem;
  }

}
@media only screen and (max-width: 1919px) {
  .Blog-detai-heading {
    margin-top: 6rem;
  }
  .service-sec-heading {
    margin-top: 14rem;
  }
  .blog-inner-page-heading {
    padding-top: 25rem;
  }
}
@media only screen and (max-width: 1600px) {
  .Blog-detai-heading {
    margin-top: -3rem;
  }
  /* home-page */
  .blog-inner-page-heading {
    padding-top: 20rem;
  }
  .get-ready-sec {
    padding-bottom: 416px;
  }
  .carousel-img-name {
    font-size: 12px;
  }
  .glow-up-sec {
    padding-top: 80px;
  }
  .Sustainability-sec {
    padding-bottom: 100px;
  }
  .blue-white-btn {
    padding-top: 2rem;
    padding-bottom: 10px;
  }
  .as-i-see {
    margin-top: -17rem;
  }

  /* about-page */

  .about-sec-heading {
    margin-top: -50px;
  }
  .image-sec {
    background-position: bottom;
  }
  .our-team-inner:before {
    background-size: 100%;
    height: 66%;
    width: 74%;
  }
  .our-team {
    padding: 100px 0px 200px;
  }

  /* blog-page */

  .blog-image-sec {
    background-position: bottom;
  }

  /* serive-page */

  /* .Sustainability-sec */

  .Sustainability-new-sec .blog-detail-sec-inner {
    margin-top: 4rem;
  }

  /* chome-sec */

  .cbanner-sec-inner-heading {
    font-size: 42px;
  }
  .cwhite-btn a {
    font-size: 14px;
    padding: 18px 21px;
  }
  .what-vendior-heading {
    font-size: 36px;
  }
  .what-vendior-title {
    font-size: 14px;
  }
  .what-vendior-cards-desc {
    font-size: 14px;
    padding: 23px 14px;
  }
  .what-vendior-left-cards {
    margin-top: 2rem;
  }
  .cslider-uper-heading {
    font-size: 42px;
  }
  .cslider-uper-title {
    font-size: 14px;
  }
  img.download-img-left {
    max-width: 57%;
  }
  .download-sec-left {
    text-align: center;
  }
  .download-sec-title {
    font-size: 16px;
    line-height: 24px;
    padding-top: 4px;
  }
  .download-sec-heading {
    font-size: 36px;
  }
  .download-link img {
    width: 77%;
  }
  .download-link {
    gap: 0px;
    padding-top: 2rem;
  }
  .choose-vendior-heading {
    font-size: 36px;
  }
  .choose-vendior-title {
    font-size: 16px;
  }
  .blue-btn a {
    padding: 14px 16px;
    font-size: 14px;
  }
  .about-blog-title {
    font-size: 36px;
  }
  .about-blog-desc {
    font-size: 16px;
    padding-top: 5px;
  }
  .cards-sec-inner-left-question {
    font-size: 26px;
  }
  li.cards-sec-inner-left-list-desc {
    font-size: 14px;
    padding-bottom: 18px;
  }
  .cards-sec-inner-right-question {
    font-size: 26px;
  }
  li.cards-sec-inner-right-desc {
    font-size: 14px;
    padding-bottom: 19px;
  }
  .glow-up-heading {
    font-size: 42px;
  }
  .blog-sec-inner {
    padding-top: 4rem;
  }
  .starter-package h4 {
    font-size: 26px;
  }
  .package-list ul li {
    font-size: 15px;
  }
  .package-btn {
    padding: 10px 26px;
    font-size: 16px;
  }
  .key-features-heading {
    font-size: 36px;
    padding-bottom: 2rem;
  }
  .key-features-inner {
    margin-top: 3rem;
  }
  .Inclusivity-title {
    font-size: 14px;
  }
  .our-focus {
    padding-top: 2rem;
  }
  .our-focus-card-desc p {
    font-size: 14px;
  }
  .our-focus-card {
    padding: 14px 20px;
  }
  .embrace-sec-heading {
    font-size: 36px;
  }
  .embrace-sec-title {
    font-size: 14px;
  }
  .earn-extra-money-heading {
    font-size: 36px;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .friend-info-inner {
    width: 73%;
    padding: 33px 25px;
  }
  .friend-info {
    padding: 50px 0px;
  }
  .friend-info-inner-heading {
    padding-bottom: 2rem;
  }
  .earn-extra-money-title {
    font-size: 16px;
    line-height: 1.3;
  }
  .service-sec-heading {
    margin-top: 6rem;
}
  .service-sec-heading {
    margin-top: 9rem;
  }
  .service-main-title {
    font-size: 22px;
  }
  .service-main-desc {
    font-size: 14px;
  }
  .blue-bg {
    padding: 36px 0px;
    margin: 50px 0rem;
  }
  .step1-heading {
    font-size: 38px;
  }

  /* premium-sec */
  .vendior-different-heading {
    font-size: 38px;
   }
   .premium-sec-heading.text-center {
    font-size: 38px;
   }
   .premium-sec {
    padding-top: 12px;
   }


 .unique-event {
    font-size: 36px;
   }
   .unique-together-title {
    font-size: 16px;
  }
  .unique-together-desc {
    font-size: 14px;
}
  video.banner-video {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    object-fit: cover;
}
}
@media only screen and (max-width: 1399px) {
  .thank-you-heading {
    font-size: 96px;
  }
  .partner-img-inner img {
    width: 70%;
  }
  /* heading-sec */

  .navbar-light .navbar-nav .nav-link {
    font-size: 15px;
  }

  /* footer-sec */

  a .list-title {
    font-size: 14px;
  }
  .footer-main {
    font-size: 16px;
  }

  /* home-page */
  .banner-heading {
    font-size: 46px;
  }
  .carousel-img-name {
    font-size: 12px;
  }
  img.banner-slide-img:nth-child(1) {
    top: 18%;
  }
  img.banner-slide-img:nth-child(7) {
    top: 18%;
  }
  img.banner-slide-img:nth-child(6) {
    top: 13%;
  }
  img.banner-slide-img:hover {
    -webkit-transform: rotate(0deg) scale(1.1) translateY(-53px);
        -ms-transform: rotate(0deg) scale(1.1) translateY(-53px);
            transform: rotate(0deg) scale(1.1) translateY(-53px);
  }
  .banner-slider-sec-inner {
    padding-top: 53rem;
  }
  img.banner-slide-img:nth-child(2) {
    top: 12%;
  }
  img.banner-slide-img:nth-child(5) {
    top: 5%;
  }
  .event-planning-list li {
    font-size: 16px;
  }
  .as-i-see {
    margin-top: -39rem;
  }
  .blue-btn a {
    padding: 16px 20px;
    font-size: 14px;
  }
  .whit-btn a {
    padding: 17px 15px;
    font-size: 14px;
  }
  .as-i-see-haeding {
    font-size: 38px;
  }
  .get-ready-sec {
    padding-top: 73px;
    padding-bottom: 329px;
  }
  .cards-sec-inner2 {
    padding: 26px 40px;
  }
  .cards-sec-inner {
    padding: 49px 37px;
  }
  .glow-up-card-inner-heading {
    font-size: 16px;
  }
  .glow-up-card-inner-title {
    font-size: 13px;
  }
  .glow-icon img {
    width: 76%;
  }
  .services-heading {
    font-size: 34px;
  }
  .services-title {
    font-size: 14px;
  }
  .cabout-our-team {
    padding-bottom: 137px;
  }
  .Contactform-sec {
    padding: 55px 0px;
  }

  .our-value-sec {
    padding-top: 50px;
  }
  .event-planning-title {
    font-size: 27px;
    padding-bottom: 15px;
  }
  .Vendoir-different {
    padding-bottom: 60px;
  }
  .Sustainability-title {
    font-size: 18px;
  }
  .get-ready-left-heading {
    font-size: 20px;
    line-height: 1.5;
  }
  .glow-up-heading {
    font-size: 28px;
  }
  .commitment-sec {
    padding: 50px 0px;
  }
  .Who-we-are-main {
    margin-left: 0px;
  }
  .help-main {
    padding-left: 30px;
  }
  .scanner-services.text-end img {
    width: 100%;
    max-width: 62%;
  }
  img.services-left-img {
    width: 100%;
    max-width: 67%;
  }
  .Vendoir-heading {
    font-size: 38px;
    padding-bottom: 72px;
  }
  .Sustainability-card-desc p {
    font-size: 12px;
  }
  .Sustainability-card-desc h4 {
    font-size: 15px;
  }
  .Sustainability-card {
    padding: 13px 12px;
  }
  .key-features-heading {
    font-size: 28px;
    padding-bottom: 1rem;
  }
  .Inclusivity-heading {
    font-size: 15px;
  }
  .service-award img {
    width: 100%;
    max-width: 42%;
  }
  .services-sec-left.text-left {
    text-align: center;
  }
  .service-link img {
    width: 130px;
  }
  /* about-sec */

  .image-sec {
    padding: 230px 0px 100px;
  }
  .about-sec-heading {
    font-size: 34px;
    margin-top: -31px;
  }
  .image-title {
    font-size: 18px;
  }
  .cards-sec-inner-left-question {
    font-size: 26px;
  }
  li.cards-sec-inner-right-desc {
    font-size: 13px;
  }
  li.cards-sec-inner-left-list-desc {
    font-size: 13px;
  }
  .cards-sec-inner-right-question {
    font-size: 25px;
  }
  .cards-sec {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .our-team {
    padding: 50px 0px 171px;
  }
  .our-team-desc {
    font-size: 14px;
  }
  .team-heading {
    font-size: 36px;
  }
  .about-blog-desc {
    font-size: 14px;
    padding-top: 5px;
  }
  .about-blog-title {
    font-size: 32px;
  }
  .our-team-inner::before {
    background-size: 100%;
    width: 80%;
    height: 57%;
    bottom: 21px;
    right: 122px;
  }

  /* blog-page */

  .blog-sec-heading {
    margin-top: -39px;
    font-size: 38px;
  }
  .our-blog-bottom {
    padding-top: 0px;
  }
  .business-blog-card-right-heading {
    font-size: 22px;
  }
  .business-blog-card-right-desc {
    font-size: 14px;
  }
  .business-blog-card-right-date {
    font-size: 14px;
  }
  .business-blog-card-right-title {
    font-size: 14px;
  }
  .our-blog-categories-heading {
    font-size: 22px;
  }
  li.blog-categories-list-name {
    font-size: 16px;
  }
  .item {
    width: 6%;
  }
  span.icon-name {
    bottom: 32%;
    left: -72px;
  }

  /* chome-page */

  .cbanner-sec-inner-heading {
    font-size: 36px;
  }
  .what-vendior-heading {
    font-size: 28px;
  }
  .download-sec-heading {
    font-size: 28px;
  }
  .choose-vendior-title {
    font-size: 14px;
  }
  .vendior-card-desc h4 {
    font-size: 14px;
  }
  .choose-vendior-card {
    padding: 18px 22px;
    margin-top: 1rem;
  }
  .download-link {
    padding-top: 1rem;
  }
  .download-link img {
    width: 70%;
  }
  .choose-vendior {
    padding-bottom: 68px;
  }
  .cslider-uper-heading {
    font-size: 28px;
  }
  .Contactform-inner-left {
    padding: 35px 26px;
  }
  .Contactform-inner-left-heading {
    font-size: 22px;
  }
  .Contactform-inner-left-title {
    font-size: 16px;
    padding-bottom: 2rem;
  }
  .what-vendior {
    padding-top: 66px;
  }
  .choose-vendior-title {
    font-size: 16px;
  }
  .choose-vendior-card-icon {
    padding: 3px 8px;
  }

  /* service-page */

  .service-sec-heading {
    margin-top: 8rem;
  }
  .service-main-title {
    font-size: 28px;
  }
  .service-main-desc {
    font-size: 16px;
  }
  li.service-list-name {
    padding-bottom: 16px;
  }

  /* blog-detai-page */
  .Blog-detai-heading {
    font-size: 65px;
  }
  .Blog-detai-title {
    font-size: 22px;
  }
  .blog-detail-desc {
    font-size: 20px;
  }
  li.blog-deatils-card-title br {
    display: none;
  }
  .blog-deatils-card-title {
    font-size: 20px;
  }
  .Blog-detai-heading {
    font-size: 38px;
  }
  .mtop {
    margin-top: 2rem;
  }
  .blog-detail-bottom-lists-name {
    font-size: 18px;
  }
  .bsupply-middle.text-center {
    padding: 2rem;
  }

  /* Sustainability-sec */

  .Sustainability-new-title {
    font-size: 16px;
  }
  .our-value-card {
    padding: 20px 18px;
  }
  .our-focus-card-icon {
    padding: 10px;
    text-align: center;
  }
  .our-focus-card-icon svg {
    width: 100%;
    max-width: 73%;
  }
  .embrace-sec-heading {
    font-size: 28px;
  }

  /* referfriend-sec */
  .friend-info-inner-heading {
    font-size: 20px;
  }
  .friend-info-inner {
    width: 82%;
  }
  .extra-money-desc {
    font-size: 16px;
  }
  .earn-extra-money-heading {
    font-size: 30px;
  }
  .faq-heading {
    font-size: 44px;
  }
  .faq-title {
    font-size: 16px;
  }
  .accordion {
    padding-top: 2rem;
  }

  /* bsupply-sec */

  .bsupply-middle-heading {
    font-size: 26px;
  }
  .bsupply-middle-title {
    font-size: 18px;
  }

  /* our-package-service */
  .starter-package {
    padding: 30px 20px;
  }
  .starter-package h4 {
    font-size: 20px;
  }
  .package-list ul li {
    font-size: 14px;
  }
  .step-2-list ul li {
    width: calc(100% / 6);
  }
  .step-2-list ul {
    padding-bottom: 3rem;
  }
  .dropbtn {
    font-size: 16px;
  }
  .our-focus-card-desc h4 {
    font-size: 15px;
  }
  .our-focus-card-desc p {
    font-size: 13px;
  }
  .step1-heading {
    font-size: 32px;
  }
  p.provide-content {
    font-size: 15px;
  }
  .bsupply-sec-inner label.checkbox-desc {
    font-size: 15px;
  }

  /* blog-inner-page */
  .blog-inner-page-heading {
    padding-top: 17rem;
  }
  .coustom-business-link {
    white-space: normal;
  }

  /* premium-sec */
  .vendior-different-heading {
    font-size: 30px;
  }
  .vendior-different-list-heading {
    font-size: 24px;
   }
   .vendior-different-list {
    font-size: 14px;
   }
   .glow-up-card-heading {
    font-size: 18px;
   }
   .glow-up-card-title {
    font-size: 14px;
   }
   .premium-sec-heading.text-center {
    font-size: 31px;  
   }
   .premium-heading {
    font-size: 18px;
   }
   .premium-inner-sec {
      padding-top: 40px;
      padding-bottom: 70px;
    }
    .premium-desc {
      font-size: 14px;
  }

  /* .unique-together-sec */
  .unique-together-title {
    font-size: 16px;
  }
  .unique-together-desc {
    font-size: 14px;
  }
  .unique-event.text-center {
    padding-bottom: 3rem;
 }
 .unique-event {
  font-size: 28px;
}
.unique-together-sec {
  padding: 10px 0px 50px;
}

.cbanner-sec {
    margin-top: 0rem;
    padding-top: 150px;
    padding-bottom: 0;
}
.cbanner-sec {
  margin-top: 0rem;
  padding-top: 150px;
  padding-bottom: 120px;
}
}
@media only screen and (max-width: 1199px) {
  /* condition-pages */
  .privacy-heading h5 {
    font-size: 14px;
  }
  .privacy-heading h1 {
    font-size: 36px;
  }
  .privacy-heading {
    padding-bottom: 20px;
  }
  .privacy-main-desc {
    margin-bottom: 20px;
  }
  .inner-get-app {
    margin: 3rem 0;
}

  .privacy-title {
    font-size: 20px;
  }
  .privacy-sub-title {
    color: #242424;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 12px;
  }
  .thank-you-heading {
    font-size: 70px;
  }
  .thank-you-title {
    font-size: 16px;
  }
  /* home-sec */
  .slider2 {
    margin-top: 4rem;
    padding: 1rem 0px;
  }
  .dropdown button {
    font-size: 13px;
  }
  /* .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0.5rem !important;
  } */
  .banner-heading {
    font-size: 38px;
  }
  p.banner-title br {
    display: none;
  }
  .banner-title {
    font-size: 16px;
  }
  .services-sec-left {
    text-align: center;
  }
  .scanner-services img {
    width: 59%;
  }
  .banner-left-inner img {
    width: 34%;
  }
  .banner-slider-sec-inner {
    padding-top: 37rem;
  }
  .as-i-see {
    margin-top: -25rem;
  }
  .services-title {
    font-size: 13px;
  }
  .services-heading {
    font-size: 38px;
  }
  .Vendoir-heading {
    font-size: 38px;
    padding-bottom: 50px;
  }
  .event-planning-title {
    font-size: 22px;
    padding-bottom: 13px;
  }
  .event-planning-list li {
    font-size: 16px;
  }
  .coming-soon {
    font-size: 38px;
  }
  .Sustainability-heading {
    font-size: 32px;
  }
  .Sustainability-card {
    padding: 19px 13px;
  }
  .Sustainability-card-icon {
    padding: 9px 10px;
}
  .Sustainability-sec {
    padding-bottom: 50px;
    padding-top: 44px;
  }
  .Sustainability-card-desc p {
    font-size: 12px;
  }
  .Sustainability-card-desc h4 {
    font-size: 15px;
  }
  .glow-up-card-inner-heading {
    font-size: 18px;
  }
  .glow-up-card-inner-title {
    font-size: 14px;
  }
  .cards-sec-inner-question {
    font-size: 26px;
    padding-bottom: 0px;
  }
  .cards-sec-inner {
    padding: 20px 30px;
  }
  .cards-sec-inner2 {
    padding: 24px 20px;
  }
  .card-list-desc {
    font-size: 12px;
  }
  .cards-sec-inner2 {
    padding: 20px 35px;
  }
  .get-ready-left-heading {
    font-size: 20px;
  }
  .get-ready-sec {
    padding-bottom: 283px;
  }
  .Gapp-sec {
    background-size: cover;
  }
  .glow-up-heading {
    font-size: 35px;
  }
  .banner-slider-sec-inner {
    margin-top: 5rem;
  }
  .worldslider-title.text-center {
    font-size: 16px;
  }

  .swiper-slide .worldslider-heading {
    font-size: 22px;
  }
  .worldsilder-sec .swiper-slide.swiper-slide-next .worldslider-heading {
    font-size: 22px;
  }
  .worldsilder-sec .swiper-slide {
    padding: 14px 15px;
  }
  .swiper-slide .worldslider-title {
    font-size: 12px;
  }
  .worldsilder-sec .swiper-slide.swiper-slide-next .worldslider-title {
    font-size: 12px;
  }
  .blog-sec {
    padding: 30px 0px;
  }
  .blog-title {
    font-size: 16px;
  }
  .blog-heading {
    font-size: 17px;
  }
  .blog-desc {
    font-size: 13px;
  }
  .blog-date {
    font-size: 16px;
  }
  .footer-main {
    font-size: 15px;
  }
  a .list-title {
    font-size: 14px;
  }
  .footer-inner-bottom img {
    width: 52px;
  }

  /* about-sec */
  .image-sec {
    background-size: cover;
    margin-top: -6rem;
  }
  .about-sec-heading {
    font-size: 42px;
    margin-top: -38px;
  }
  .image-title {
    font-size: 18px;
  }
  .pt-5 {
    padding-top: 2rem !important;
  }
  .pb-5 {
    padding-bottom: 2rem !important;
  }
  .blog-cards-sec-inner {
    padding: 28px 20px;
  }
  .blog-cards-sec-inner2 {
    padding: 30px 18px 149px;
  }
  .our-team {
    padding: 80px 0px 200px;
  }
  .our-team-inner::before {
    background-size: 99%;
    width: 93%;
    height: 62%;
    bottom: -19px;
    right: 38px;
  }
  .service-sec-heading {
    padding-bottom: 0rem;
  }
  .as-i-see-sec-img {
    gap: 8px;
  }
  .glow-up-heading {
    font-size: 29px;
  }
  .commitment-sec {
    padding: 80px 0px;
  }
  .glow-up-card-inner-heading {
    padding-bottom: 7px;
  }
  .glow-icon {
    margin-bottom: 20px;
  }

  /* blog-page */
  .blog-image-sec {
    padding: 13rem 0rem;
  }
  .blog-sec-heading {
    margin-top: -29px;
    font-size: 36px;
  }
  .our-blog-inner-right {
    margin-left: 1rem;
  }
  .business-blog-card-right-heading {
    font-size: 20px;
  }
  .business-blog-card-right-desc {
    font-size: 12px;
  }
  a.business-blog-card-right-link {
    font-size: 13px;
  }
  .business-blog-card {
    padding: 26px 0px 20px;
  }
  .our-blog-categories {
    padding: 24px 22px;
  }
  .Bsuppliy-sec {
    background-position: center;
  }

  /* chome-sec */

  .what-vendior {
    padding-top: 55px;
  }
  .what-vendior-left-cards {
    margin-top: 2rem;
  }
  .what-vendior-cards-desc {
    font-size: 14px;
    padding: 21px 13px;
  }
  .download-link {
    gap: 0px;
  }
  .choose-vendior-card-icon svg {
    width: 46px;
  }
  .choose-vendior-card-icon {
    padding: 8px 8px;
}
  .vendior-card-desc h4 {
    font-size: 12px;
  }
  .choose-vendior-title {
    font-size: 16px;
  }
  .choose-vendior-title br {
    display: none;
  }
  .choose-vendior-card {
    padding: 11px 16px;
  }
  .Contactform-sec-heading {
    font-size: 30px;
  }
  .about-blog-title {
    font-size: 28px;
  }
  .Contactform-sec-title {
    font-size: 16px;
  }
  .Contactform-inner-title {
    font-size: 14px;
  }
  .Contactform-inner-left-title {
    font-size: 14px;
    padding-bottom: 1rem;
  }
  .Contactform-inner-left {
    padding: 35px 22px;
  }
  .package-btn {
    font-size: 14px;
  }
  .rbusiness-title {
    font-size: 14px;
  }

  /* service-page */

  .blue-bg {
    padding-top: 40px;
    margin: 35px 0px;
  }
  a.sign-btn {
    font-size: 14px;
    padding: 14px 30px;
  }
  button.sign-btn {
    font-size: 14px;
    padding: 14px 30px;
  }
  .our-partner-heading {
    font-size: 32px;
  }
  img.partner-img-fluid {
    width: 78%;
  }
  .package-list ul li {
    font-size: 12px;
  }
  .package-btn {
    font-size: 14px;
  }
  .service-sec-heading {
    margin-top: -2rem;
  }
  .partner-img {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 50px;
  }
  .service-main-title {
    font-size: 18px;
  }
  .service-main-desc {
    font-size: 13px;
  }
  li.service-list-name {
    padding-bottom: 14px;
  }

  /* cabout-sec */
  .Inclusivity-title {
    font-size: 14px;
  }
  .our-value-card {
    padding: 18px 16px;
  }

  /* blog-deatil-sec */

  .Blog-detail-image-sec {
    background-size: contain;
  }
  .blog-detail-sec-inner {
    margin-top: -22rem;
  }
  .Blog-detai-heading {
    font-size: 37px;
  }
  .bsupply-sec-inner {
    margin-bottom: 9rem;
  }
  .blog-deatils-card-title {
    font-size: 16px;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .blog-deatils-card {
    padding-bottom: 3rem;
  }
  .team-vendior {
    font-size: 20px;
    padding-bottom: 2rem;
  }

  /* header-sec */
  .navbar-light .navbar-nav .nav-link {
    font-size: 13px;
  }

  /* footer-sec */
  a .list-title {
    font-size: 12px;
  }
  label.checkbox-desc {
    font-size: 12px;
  }

  /* .Sustainability-sec */

  .Sustainability-new-sec .blog-detail-sec-inner {
    margin-top: -3rem;
  }
  .Sustainability-new-title {
    font-size: 16px;
  }
  .our-focus {
    padding-top: 2rem;
  }
  .key-features-heading {
    padding-bottom: 1rem;
  }
  .our-focus-card-desc p {
    font-size: 14px;
  }
  .our-focus-card {
    margin-bottom: 1rem;
    padding: 15px 20px;
  }
  .embrace-sec-heading {
    font-size: 38px;
  }
  .embrace-sec-title {
    font-size: 16px;
  }

  /* referfriend-sec */

  .earn-extra-money-heading {
    padding-top: 3rem;
  }
  li.extra-money-list-name {
    font-size: 12px;
  }
  .accordion-header-name {
    font-size: 18px;
  }
  .friend-info-inner {
    width: 92%;
  }

  /* gpackage-sec */

  .Gpackage-sec {
    padding-top: 0rem;
  }


  /* about-us-sec */
  .commitment-card-inner-title {
    font-size: 12px;
  }
  .commitment-icon {
    padding: 13px;
  }
  .commitment-card-inner-heading {
    font-size: 18px;
  }
  /* our-package-sec */

  .Ourpackage-image {
    background-position: center;
  }
  .sub-packages {
    margin: 4rem 0px;
  }
  img.stepper-logo {
    max-width: 26%;
  }
  .Bstepper-sec-uper {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 0rem;
  }
  .step-2-list ul li {
    width: calc(100% / 5);
  }
  .Btepper-sec-card.step-2-list ul {
    padding: 0 2rem;
  }

  /* blog-detail */

  .blog-detail-bottom-lists-name {
    font-size: 16px;
  }
  .step1-heading {
    font-size: 28px;
  }
  p.step1-desc br {
    display: none;
  }
  .step1-desc {
    font-size: 15px;
  }

  /* resource-pages */

  .reesource-main-desc {
    font-size: 14px;
  }
  .resource-sec-title {
    font-size: 32px;
  }

  /* blog-inner-page */
  .blog-inner-page-heading {
    padding-top: 10rem;
    font-size: 38px;
  }
  .blog-inner-bottom {
    padding: 52px 0px;
  }
  p.blog-inner-lists-title br {
    display: none;
  }
  .vendior-date {
    font-size: 20px;
    padding-top: 16px;
  }
  .Blog-detai-heading {
    margin-top: 5rem;
  }
  .banner-right img {
    width: 420px;
}

  /* sitemap-sec */

  .choose-vendior-card-desc {
    font-size: 13px;
  }

  .welcome-inner-right {
    padding: 150px 36px;
  }
  .welcome-inner-left {
  padding: 150px 36px;
  }
  .welcomepage-heading {
    font-size: 30px;
}
.growth-package:before {
  width: 101px;
  right: -2px;
  height: 130px;
}
.our-partner .container {
  max-width: 960px;
}
.cbanner-sec {
  padding-bottom: 80px;
}
button.play-button {
  top: 88%;
}
.cbanner-sec-inner {
  margin-bottom: 52px;
}

.service-firstCont-title {
  font-size: 40px;
}
.service-firstCont-para {
  margin-top: 20px;
  font-size: 23px;
}
.service-firstCont-rating {
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 15px;
}
.star-rating-img {
  width: 40%;
}
  /* .coustom-Business-link::before {
    width: 440px;
    right: -162px;
  }
  .vendior-btn::before {
    width: 428px;
    right: -133px;
  }
  .coustom-Business {
    gap: 16rem;
    padding-top: 4rem;
} */
}
@media only screen and (max-width: 991px) {
  .cslider-sec,
  .video-download {
    padding-bottom: 40px;
  }
  .video-download {
    width: 90%;
  }
  .video-download iframe {
    width: 100%;
    height: auto;
  }
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px !important;
  }

  .hero-sub-text-home {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .get-mobile-app {
    display: inline-block !important;
    margin-left: 2.5rem;
  }
  .get-desktop-app {
    display: none !important;
  }
  .step-bottom.text-center {
    width: 100%;
  }
  .Bsuppliy-sec,
  .Referbusiness-image-sec,
  .Referfriend-image-sec {
    padding: 150px 0px;
  }
  .date-time input[placeholder] {
    width: 100%;
  }
  .date-time {
    width: 100%;
  }

  /* condition-pages */
  .privacy-desc {
    font-size: 14px;
  }
  .privacy-content h3 {
    font-size: 14px;
  }
  .Ourpackage-image {
    margin-top: -8rem;
  }
  /* home-page */

  .as-i-see {
    margin-top: -15rem;
  }
  .Sustainability-inner-right img {
    max-width: 47%;
  }
  .glow-up-card {
    padding: 26px 26px;
  }
  /* header-sec */
  collapse:not(.show) {
    display: none !important;
  }
  div#basic-navbar-nav {
    width: 40%;
    -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
            order: unset;
  }
  .navbar-light .navbar-brand {
    -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
            order: unset;
  }
  .header-button {
    width: 20rem !important;
    /* margin-left: -1rem !important; */
    -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
            order: unset;
  }
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-sm,
  .navbar > .container-md,
  .navbar > .container-lg,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .navbar-light .navbar-toggler {
    border-color: none !important;
  }
  button.navbar-toggler.collapsed {
    display: block;
  }
  span.navbar-brand {
    width: 42%;
  }
  .header-logo .img-fluid {
    max-width: 73%;
    height: auto;
  }
  .header-button {
    margin: auto;
    text-align: center;
    margin-top: 2rem;
}
  a.nb-button {
    font-size: 15px;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("./image/mobile-nb-img.png") !important;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 22px !important;
    padding-top: 25px;
    padding-bottom: 26px;
    font-weight: 600 !important;
  }
  .navbar-nav {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .nav-link.active {
    border-bottom: 1px solid #000;
  }
  a.nav-link.tab-link {
    display: block !important;
  }
  section.header {
    background-image: url("image/mobile-header-bg.png") !important;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-color: #4a67ab;
  }
  div#basic-navbar-nav {
    width: 40%;
    height: 100vh;
    padding: 10% 0px 0%;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 24px !important;
    padding-top: 20px;
    padding-bottom: 2rem;
  }
  .navbar-expand-lg .navbar-collapse {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  /* header-sec */

  .banner-slider-sec-inner {
    padding-top: 28rem;
    margin-top: 8rem;
  }
  .banner-heading {
    text-align: center;
  }
  .dropdown button {
    font-size: 21px;
}
  p.text-center.rbusiness-title br {
    display: none;
  }
  .friend-info-inner {
    margin-bottom: 3rem;
  }
  .banner-title {
    text-align: center;
  }
  .banner-left-inner {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .banner-left-inner img {
    width: 20%;
  }
  img.social-link {
    width: 87%;
  }
  .scanner-services img {
    width: 70%;
  }
  .banner-social-link {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .banner-right {
    text-align: center;
  }
  .Gapp-sec {
    background-size: cover;
    height: unset;
  }
  img.services-left-img {
    max-width: 58%;
  }
  img.banner-slide-img:nth-child(1) {
    width: 30%;
  }
  img.banner-slide-img:nth-child(2) {
    width: 30%;
  }
  img.banner-slide-img:nth-child(3) {
    width: 30%;
  }
  img.banner-slide-img:nth-child(4) {
    width: 30%;
  }
  img.banner-slide-img:nth-child(5) {
    width: 30%;
  }
  img.banner-slide-img:nth-child(6) {
    width: 30%;
  }
  img.banner-slide-img:nth-child(7) {
    width: 30%;
  }
  .ready-img-middle {
    margin-top: 0rem;
  }
  .ready-img {
    width: 25%;
  }
  .readi-img-last {
    margin-top: -0.5rem;
  }
  .services-sec-left {
    padding-top: 5rem;
  }
  .banner-social-link {
    margin-top: 9%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .Vendoir-different-left {
    margin-top: 7rem;
  }
  .Sustainability-inner-right {
    margin-top: 4rem;
  }
  .glow-icon img {
    width: 52%;
  }
  .glow-icon {
    padding: 8px;
  }
  .banner-slider-sec-inner {
    margin-top: 6rem;
  }
  .glow-up-card-inner-heading {
    font-size: 15px;
  }
  .glow-up-card-inner-title {
    font-size: 12px;
  }
  .cards-sec-inner-question {
    font-size: 21px;
  }
  .cards-sec-inner {
    padding: 33px 27px 63px;
  }
  img.download-img-left {
    max-width: 40%;
  }
  .get-ready-left {
    text-align: center;
    padding-bottom: 4rem;
  }
  .carousel-img {
    gap: 0px;
  }
  .carousel-icon .img-fluid {
    max-width: 79%;
    height: auto;
  }
  .get-ready-left-heading {
    margin-top: 3rem;
  }
  .carousel-img-name {
    font-size: 10px;
  }
  .blue-btn a {
    padding: 18px 23px;
    font-size: 14px;
  }
  .whit-btn a {
    padding: 17px 10px;
    font-size: 14px;
  }
  .worldslider-title.text-center {
    padding-bottom: 34px;
  }
  .glow-blue-btn a {
    padding: 18px 17px;
    font-size: 14px;
  }
  .glow-whit-btn a {
    padding: 18px 12px;
    font-size: 14px;
  }
  .cards-sec-inner-left {
    margin-bottom: 2rem;
  }
  .get-ready-sec {
    padding-bottom: 466px;
  }
  .footer-social-payment img {
    width: 30%;
  }
  .Who-we-are {
    padding-top: 3rem;
  }
  .Help {
    padding-top: 3rem;
  }
  .Subscribe {
    padding-top: 3rem;
  }
  .footer-main {
    font-size: 13px;
  }
  img.footer-logo {
    width: 34%;
  }
  .Who-we-are-main {
    margin-left: -46px;
  }
  .footer-social-link {
    width: 28%;
  }

  /* about-sec */
  .blog-cards-sec-inner2 {
    padding: 30px 18px 58px;
    margin-top: 2rem;
  }
  p.commitment-card-inner-title br {
    display: none;
}
  .our-team-desc br {
    display: none;
  }
  .our-team-inner::before {
    bottom: -177px;
    height: 68%;
  }
  .cabout-our-team {
    padding-bottom: 200px;
  }
  .our-team {
    padding: 40px 0px 250px;
  }
  .glow-up-heading {
    font-size: 25px;
  }
  .item-content-list li {
    font-size: 15px;
  }
  .Contactform-sec-title {
    font-size: 14px;
  }
  .See-more {
    margin-bottom: 4rem;
  }
  img.partner-img-fluid {
    width: 69%;
  }
  .item {
    width: 7%;
  }
  .item-content-list li {
    font-size: 14px;
  }
  .item {
    width: 9%;
  }
  span.icon-name {
    bottom: 32%;
    left: -65px;
  }
  span.icon-name {
    font-size: 29px;
  }

  /* blog-page */

  .blog-image-sec {
    padding: 11rem 0rem;
  }
  .blog-sec-heading {
    margin-top: -32px;
  }
  .our-blog-inner-right {
    margin-left: 0rem;
  }
  .business-blog-card {
    padding: 38px 0px 28px;
    margin-bottom: 1rem;
  }
  .our-blog-search {
    margin-top: 2rem;
  }
  .our-blog-inner-left {
    margin-top: 3rem;
  }

  /* chome-sec */

  .what-vendior-right {
    margin-left: 0rem;
    margin-top: 3rem;
  }
  .download-sec-right {
    text-align: center;
  }
  .download-link {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 2rem;
  }
  .download-sec {
    background: #4a67ab;
    padding-top: 30px;
  }
  .cslider-uper {
    padding-bottom: 2rem;
  }
  .cslider-sec {
    padding-top: 32px;
  }

  .download-sec-left {
    text-align: center;
  }
  .choose-vendior-left {
    text-align: center;
    padding-top: 4rem;
  }
 

  /* serivice-page */

  .service-sec-heading {
    margin-top: -6rem;
  }
  .service-sec-heading {
    font-size: 45px;
  }
  img.service-img {
    width: 100%;
  }
  .our-patner-inner {
    text-align: center;
  }


  /* cabout-page */

  .margin-card {
    margin-top: 0;
  }
  .our-value-card {
    margin-bottom: 22px;
  }

  /* blog-detail */

  .blog-detail-sec-inner {
    margin-top: -24rem;
  }
  .Blog-detai-title {
    font-size: 18px;
    padding-top: 0rem;
  }
  .blog-detail-desc {
    font-size: 16px;
    padding-top: 1rem;
    padding-bottom: 0rem;
  }
  .blog-deatils-card {
    padding-bottom: 0rem;
  }

  /* footer-sec */

  a .list-title {
    font-size: 16px;
  }
  .footer-main {
    font-size: 18px;
  }

  /* referfriend-sec */

  .earn-extra-money-heading {
    padding-bottom: 1rem;
  }
  p.faq-title br {
    display: none;
  }
  .accordion {
    padding-top: 0rem;
  }
  .earn-extra-money {
    padding-bottom: 2rem;
  }

  /* bsupply-sec */

  .Referbusiness-image-sec {
    background-position: center;
  }
  .bsupply-middle.text-center {
    padding: 3rem;
  }
  .bsupply-middle-heading {
    font-size: 31px;
  }
  .bsupply-middle-title {
    font-size: 16px;
  }

  /* gpackage-sec */
  .Gpackage-image {
    background-position: left;
  }

  /* our-package-service */

  .sub-packages .row {
    gap: 2rem;
  }
  p.Sustainability-new-title br {
    display: none;
  }

  /* stepper-sec */

  .step2-list-desc- {
    font-size: 14px;
  }
  /* .step-2-list ul {
    gap: 15px;
  } */
  .step1-heading {
    font-size: 26px;
  }
  .step-title {
    font-size: 16px;
  }
  p.step-title.text-center br {
    display: none;
  }
  .Bstepper-sec .img-fluid {
    max-width: 55% !important;
  }
  .Btepper-sec-card.step-2-list ul {
    padding: 0 0rem;
  }

  /* resouce-page */
  .resource-sec-title {
    font-size: 26px;
  }
  .resource-sub-desc {
    font-size: 16px;
  }

  /* blog-detail-page */
  .blog-inner-page-heading {
    padding-top: 4rem;
    font-size: 30px;
  }
  .detail-inner-desc {
    font-size: 18px;
  }
  .blog-inner-lists-heading {
    font-size: 20px;
  }
  .blog-inner-lists-title {
    font-size: 16px;
  }
  .vendior-date {
    font-size: 16px;
    padding-top: 12px;
  }

/* sitemap-sec */
  .coustom-Business {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .vendior-btn {
    font-size: 20px;
  }
  .coustom {
    width: 100%;
    text-align: start;
  }
  .coustom-Business-link {
    font-size: 14px;
    padding: 12px 28px;
  }
  .Business {
    width: 100%;
    text-align: start;
  }
  .coustom-business-link {
    text-align: center;
  }
  .business-inner {
    margin-top: 1.5rem;
  }
  .coustom-inner {
    margin-top: 1.5rem;
  }

 /* app-page */
.privacy-faq .accordion-button {
  padding: 1rem 0.25rem;
  padding-right: 3rem;
 }
 img.app-logo {
  width: 32%;
  padding-bottom: 2rem;
}
.app-header button.navbar-toggler.collapsed {
  visibility: hidden;
}

.get-ready-sec {
  padding-top: 10px;
}
.premium-inner-sec {
  text-align: center;
}
.premium-card {
  padding-bottom: 2rem;
}
.premium-inner-sec {
  padding-bottom: 20px;
}
.vendior-different-desc {
  padding-top: 2rem;
}
.vendior-different-inner {
  padding-top: 2rem;
}
.glow-up-card-heading {
  font-size: 16px;
}
.glow-up-card-title {
  font-size: 12px;
}
.glow-up-card-new.text-center {
  padding: 22px 12px;
}
p.glow-up-card-title br {
  display: none;
}
.blue-white-btn {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.as-i-see-inner img {
  width: 15%;
}
.get-ready-left-heading br{
  display: none;
}
.vendior-differnet-img  img {
  width: 100%;
}
.partner-names {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 3rem;
}
.our-partner {
  padding: 50px 0px 30px;
}
.welcome-inner-right {
  padding: 100px 36px 0px;
}
.welcome-inner-left {
  padding: 100px 36px 0px;
}
.welcomepage-heading {
  font-size: 22px;
}
.welcomepage-title {
  padding: 12px 0px 30px;
}
.welcome-inner-right {
  background-size: cover;
}
.welcome-inner-left {
  background-size: cover;
}
.banner-sec {
  margin-top: 2rem;
}
.banner-left-inner {
  margin-top: 9%;
}

.unique-together-right {
  padding-top: 4rem;
}
.our-partner .container {
  max-width: 720px;
}
img.gapplogo {
  width: 100%;
}
.Gapp-social-link {
  justify-content: center;
}
.Gapp-right {
  text-align: center;
  padding-top: 25px;
}
.Gapp-content {
  padding-top: 0rem;
}
button.play-button {
  top: 87%;
}
.imgService-cont {
  margin-top: 50px;
}
.star-rating-img {
  width: 30%;
}
}
@media only screen and (max-width: 767px) {   
  .Sustainability-new-sec.Gpackage-sec.form-head {
    margin-top: 0;
  }
  h2.Blog-detai-heading.b-heading {
    margin-top: -10rem;
  }
  .date,
  .time {
    width: 100%;
  }
  .date-time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 20px;
    padding-top: 2rem;
    width: 80%;
  }
  .video-download iframe {
    height: 15rem;
  }
  .business-blog-card-left img {
    width: 100%;
  }
  .footer-social-payment {
    text-align: center;
  }
  .Gpackage-sec {
    margin-top: 10.5rem;
  }
  .mob-img {
    display: block;
  }
  .Gapp-playStore a {
    width: 30%;
  }
  /* condition-page */
  .privacy-heading h5 {
    font-size: 13px;
  }
  .privacy-heading h1 {
    font-size: 32px;
  }
  .privacy-desc {
    font-size: 12px;
  }
  .privacy-content h3 {
    font-size: 12px;
  }
  .privacy-heading {
    padding-bottom: 15px;
  }
  .privacy-main-desc {
    margin-bottom: 15px;
  }
  .privacy-title {
    font-size: 16px;
  }
  .privacy-sub-title {
    font-size: 16px;
  }
  .privacy-content {
    padding-bottom: 10px;
  }
  .Ourpackage-image {
    margin-top: -11rem;
  }
  .Blog-detai-heading {
    margin-top: 2rem;
  }
  /* header-sec */
  .navbar-light .navbar-nav .nav-link {
    font-size: 21px !important;
    padding-bottom: 2rem;
  }
  span.navbar-brand {
    width: 74%;
  }

  /* home-page */

  .banner-social-link {
    margin-top: 9%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .scanner-services.text-end img {
    max-width: 45%;
  }
  .services-sec-right {
    text-align: center;
  }
  section.banner-sec .container {
    padding: 0 20px;
  }
  img.social-link {
    width: 100%;
  }
  .banner-right img {
    width: 88%;
  }
  .blue-white-btn {
    padding-top: 3rem;
}
  .as-i-see {
    padding-bottom: 3rem;
  }
  .banner-heading {
    font-size: 44px;
  }
  .header-logo {
    width: 70%;
  }
  .banner-sec {
    padding: 200px 0px 266px;
    margin-top: -5rem;
}
  .as-i-see {
    margin-top: -18rem;
  }
  .carousel-img {
    gap: 6px;
  }
  .as-i-see-inner img {
    width: 29%;
  }
  .as-i-see-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 12px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .as-i-see-haeding {
    font-size: 42px;
  }
  .services-heading {
    font-size: 35px;
  }
  .text-end {
    text-align: left !important;
  }
  .banner-left-inner img {
    width: 34%;
  }
  .banner-social-link {
    margin-top: 13%;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .coming-soon {
    font-size: 63px;
  }
  .Sustainability-title {
    font-size: 14px;
  }
  .Sustainability-heading {
    font-size: 45px;
  }
  .Sustainability-desc {
    font-size: 15px;
  }
  .cards-sec-inner {
    padding: 33px 16px 21px;
    margin-bottom: 2rem;
  }
  .Who-we-are-main {
    margin-left: -96px;
  }
  .Who-we-are-main {
    margin-left: -103px;
  }
  .glow-up-card {
    margin-top: 0%;
    margin-bottom: 12%;
  }
  .glow-up-title {
    font-size: 16px;
    padding-bottom: 3rem;
  }
  .glow-up-heading {
    font-size: 42px;
  }
  .Subscribe {
    padding-bottom: 3rem;
  }
  .bsupply-sec {
    padding-bottom: 2rem;
  }

  /* about-sec */
  .image-title {
    font-size: 20px;
  }
  .about-sec-heading {
    margin-top: -30px;
  }
  .image-sec {
    background-image: url("./image/mobile-about.webp");
    background-size: cover;
  }
  .image-sec {
    padding: 230px 0px 130px;
  }
  .blog-sec-inner {
    padding-top: 2rem;
  }
  .row.align-items-center.pb-5 {
    padding-bottom: 0rem !important;
  }
  .as-i-see-sec-inner img {
    width: 26%;
  }
  .as-i-see-sec-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 16px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .commitment-sec {
    padding: 50px 0px;
  }
  .commitment-sec .glow-up-card {
    margin-top: 7%;
    margin-bottom: 0%;
  }
  .commitment-sec .blue-white-btn {
    margin-top: 5rem;
  }
  .our-team-inner::before {
    display: none;
  }
  .cabout-our-team {
    padding-bottom: 16px;
    padding-top: 9px;
  }
  .our-team {
    padding: 40px 0px 50px;
  }
  .as-i-see-sec {
    padding-bottom: 59px;
    padding-top: 50px;
  }
  .glow-blue-white-btn {
    padding-top: 2rem;
  }
  .get-ready-sec {
    padding-top: 0px;
  }
  .glow-blue-btn a {
    padding: 18px 15px;
    font-size: 16px;
  }
  .glow-whit-btn a {
    padding: 18px 11px;
    font-size: 15px;
  }
  .border-faq {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  span.icon-name {
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
  }
  .item {
    width: 100%;
  }
  .item {
    width: 100%;
    padding: 20px;
    margin: 0;
  }
  span.icon-name {
    bottom: 28%;
    left: 47px;
  }
  .item.active {
    background: white;
    border-radius: 15px 15px 0px 0px;
  }
  .info.active-info {
    border-radius: 0px 0px 20px 20px;
  }
  .item-content-list ul {
    margin: 0;
    padding: 0;
  }
  .item {
    margin-top: 2rem;
  }
  .cards-sec {
    padding-top: 2rem;
  }
  .commitment-card {
    margin-bottom: 0rem;
}
  /* blog-page */

  .blog-image-sec {
    background-image: url("./image/blog-mobile-bg.webp");
    background-position: bottom;
  }
  .blog-sec-heading {
    margin-top: -33px;
    font-size: 42px;
  }
  a.blog-categories-list-name {
    font-size: 15px;
  }
  .business-blog-card-right-top {
    margin-top: 28px;
  }
  .business-blog-card-right-title {
    font-size: 12px;
  }
  .business-blog-card-right-date {
    font-size: 12px;
  }
  .business-blog-card-right-heading {
    font-size: 18px;
  }
  .our-blog-inner-right {
    margin-top: 2rem;
  }
  .as-i-see-inner img {
    margin-left: 0px;
  }

  /* chome-page */
  .cbanner-sec-inner-title br {
    display: none;
  }
  .cbanner-sec {
    background-position: center;
  }

  .what-vendior-cards-desc {
    font-size: 13px;
    padding: 17px 12px;
  }
  p.what-vendior-title br {
    display: none;
  }
  .download-link img {
    width: 88%;
  }
  .cslider-uper-heading {
    font-size: 42px !important;
}
  .cslider-uper-title {
    font-size: 22px;
  }
  .cslider-uper {
    padding-bottom: 2rem;
  }
  .choose-img-mobile {
    margin: 0 auto;
    display: block;
  }
  .choose-web {
    display: none;
  }
  .Contactform-sec {
    padding: 15px 0px;
  }
  .choose-vendior-heading {
    text-align: center;
  }
  .choose-vendior-title {
    text-align: center;
  }

  /* service-page */

  .service-sec-heading {
    margin-top: -7rem;
  }
  .service-sec-heading {
    margin-top: -13rem;
  }
  .service-card {
    margin-bottom: 3rem;
  }
  .our-partner-heading {
    font-size: 28px;
  }
  .partner-name-color {
    width: 20%;
  }
  .our-partner {
    padding: 35px 0px 0px;
}
  .service-main-title {
    font-size: 17px;
  }
  .blue-bg {
    padding: 15px 0px;
    margin: 0rem 0rem;
    margin-bottom: 2rem;
  }
  .our-partner-sec {
    padding-bottom: 0rem;
  }

  /* cabout-sec */

  .margin-card {
    margin-top: 0rem;
  }
  .our-value-card {
    margin-bottom: 24px;
  }
  .our-value {
    padding-bottom: 1rem;
  }
  .our-value-heading {
    font-size: 44px;
  }

  /* blog-detail-sec */
  .Blog-detail-image-sec {
    background-size: cover;
    background-position: center;
    padding: 200px 0px;
  }

  .Sustainability-new-sec .blog-detail-sec-inner {
    margin-top: 0rem;
  }
  .Blog-detai-heading {
    font-size: 36px;
  }
  .footer-social-link {
    width: 50%;
  }
  .Subscribe {
    padding-bottom: 1rem;
  }

  /* footer-sec */

  .footer-social-payment img {
    width: 60%;
  }

  /* Sustainability-sec */
  .Sustainability-image-sec {
    background-size: cover;
    padding: 100px 0px;
    margin-top: 0px;
    background-image: url("./image/sustaintable-sec-g.webp");
  }
  p.embrace-sec-title br {
    display: none;
  }
  .embrace-sec {
    padding-top: 4rem;
  }
  .service-award img {
    max-width: 33%;
  }

  /* referfriend-sec */

  .earn-extra-money-heading {
    font-size: 25px;
    padding-top: 3rem;
  }
  .join-vendior {
    margin-top: 2rem;
    padding-bottom: 2rem;
  }
  .faq-title {
    font-size: 14px;
  }
  .accordion-header-name {
    font-size: 15px;
    width: 90%;
  }

  /* referbusiness-sec */

  .friend-info-inner-heading {
    font-size: 20px;
    padding-bottom: 2rem;
    line-height: 25px;
  }
  .friend-info-inner {
    width: 100%;
  }
  .friend-info {
    padding: 4rem 0px;
    margin-bottom: 3rem;
  }

  .Referbusiness-image-sec {
    margin-top: 0px;
    padding: 100px 0px;
    background-image: url("./image/referbusiness.webp");
    background-position: center;
  }
  .bsupply-middle.text-center {
    padding: 1rem;
  }
  .bsupply-sec-inner {
    width: 100%;
  }

  /* Gpackage-sec */

  .Gpackage-image {
    background-image: url("./image/gpackage-mobile.webp");
  }
  .Gpackage-inner {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  /* stepper-sec */
  button.next-btn {
    margin-right: 2rem;
  }

  button.prev-btn {
    margin-left: 2rem;
  }
  .step-2-list ul {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  img.stepper-logo {
    width: 100%;
    max-width: 50%;
  }
  .Bstepper-sec-uper {
    gap: 8rem;
  }
  .step-2-list ul li {
    margin-bottom: 12px;
    width: calc(100% / 3);
  }
  .Btepper-sec-card.step-2-list ul {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .checkbox-desc-ecofriendly {
    font-size: 14px;
  }
  input#otherTextField {
    width: 100%;
  }
  .step-bottom.text-center input {
    width: 100%;
  }
  p.Bthank-bottom-title br {
    display: none;
  }
  .thank-you-heading {
    font-size: 42px;
  }
  .bthank-social-link img {
    width: 40%;
  }
  img.step-1-img {
    max-width: 32%;
  }
  .step1-desc {
    font-size: 14px;
  }
  .step-2-list {
    padding-left: 0px;
  }
  p.provide-content {
    font-size: 13px;
  }
  .step3-inner .step-2-list ul li {
    margin-bottom: 15px;
    width: calc(100% / 3);
  }
  .step-bottom5 input {
    width: 24%;
  }
  .bsupply-sec-inner label.checkbox-desc {
    font-size: 14px;
  }
  a.sign-btn {
    font-size: 14px;
    padding: 16px 14px;
  }
  button.sign-btn {
    font-size: 14px;
    padding: 16px 14px;
  }
  p.step-title.mt-2 br {
    display: none;
  }
  .bsupply-sec-inner {
    margin-bottom: 0rem;
  }
  a.become-a-supply {
    font-size: 14px;
    padding: 17px 12px;
  }
  .Btepper-sec-card.step-2-list ul li {
    width: calc(100% / 3);
  }
  .service-award {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .service-link {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .service-link img {
    width: 150px;
  }

  /* blog-deatil */
  ul.blog-detail-bottom-lists {
    padding: 0;
    margin: 0;
  }
  .blog-detail-bottom-lists-name {
    text-align: center;
  }
  img.download-img-left {
    max-width: 57%;
  }
  .embrace-sec-heading {
    font-size: 26px;
  }

  /* resouce-page */

  .resource-content {
    margin-top: 15px;
  }
  .resource-sub-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .resorce-sub-content {
    margin-bottom: 20px;
  }
  .resource-sec-title {
    font-size: 26px;
  }

  /* blog-page */

  .blog-inner-page-heading {
    padding-top: 2.5rem;
  }
  .blog-inner-image-sec {
    padding: 113px 0px 0px;
  }
  .blog-inner-bottom {
    padding: 42px 0px;
  }

 /* error-page */
  .error-page {
    padding: 0px 20px;
  }
  .error-text h4 {
    font-size: 28px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .error-text p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  button.error-button {
    padding: 12px 30px;
  }
     /* app-pages */

     img.app-logo {
      width: 52%;
  }
  .banner-slider-sec-inner {
    padding-top: 22rem;
    margin-top: 14rem;
  }
  .glow-up-title {
    font-size: 14px;
    padding-bottom: 0rem;
}
.welcome-inner-left {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  height: 65vh;
}
.welcome-logo img {
  width: 60% !important;
}
.welcome-inner-right {
  height: 65vh;
}
label.vendior-works-heading {
  font-size: 16px;
}
.vendior-works-desc {
  font-size: 12px;
}
.slider1 li.react-multi-carousel-item {
  margin-right: 0px;
}
.mobile-padding {
  padding-top: 30px;
}
.cards-sec-inner-left-question {
  font-size: 20px;
} 


.unique-event.text-center {
  padding-bottom: 2rem;
}
.unique-together-right {
  padding-top: 2rem;
  text-align: center;
}
.unique-together-sec {
  padding: 10px 0px 20px;
}
.banner-mobile-video {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}
.banner-video{
  display: none;
}
.banner-mobile-video {
  height: 580px;
  object-fit: cover;
}
.partner-img img {
  width: 70%;
}
.our-partner .container {
  max-width: 100%;
}
.cbanner-sec-inner.text-center img {
  width: 60%;
}

.glow-blue-btn a {
  font-size: 12px;
}
.Vendoir-different {
  padding-top: 30px;
}
.slider-sec {
  padding-bottom: 40px;
}
}
@media only screen and (max-width: 577px) {
  /* home-page */

  .banner-heading {
    font-size: 35px;
  }
  .banner-title {
    font-size: 18px;
  }
  .banner-social-link {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .whit-btn a {
    padding: 15px 13px;
    font-size: 12px;
  }
  .blue-btn a {
    padding: 15px 18px;
    font-size: 12px;
  }
  .worldslider-title.text-center {
    font-size: 21px;
  }
  .glow-blue-btn a {
    padding: 18px 14px;
  }
  .glow-whit-btn a {
    padding: 18px 11px;
    font-size: 14px;
  }
  img.banner-slide-img:nth-child(2) {
    left: 6%;
  }
  img.banner-slide-img:nth-child(1) {
    left: -63px;
  }
  .text-end {
    text-align: left !important;
  }
  .coming-soon {
    font-size: 45px;
  }
  .blog-upper.text-center {
    padding-bottom: 0rem;
  }
  .blog-middle {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .Who-we-are-main {
    margin-left: -38px;
  }
  .text-end {
    text-align: center !important;
  }
  .footer-social-payment img {
    width: 60%;
  }
  .as-i-see-inner img {
    width: 30%;
  }
  .scanner-services.text-end {
    text-align: center !important;
  }
  .scanner-services img {
    margin-top: 3rem;
  }
  .Vendoir-heading {
    font-size: 26px;
    padding-bottom: 20px;
}
  .Vendoir-different-left {
    margin-top: 3rem;
  }
  .cards-sec-inner {
    padding: 33px 30px 21px;
    margin-bottom: 1rem;
  }
  .cards-sec-inner2 {
    padding: 33px 30px;
  }
  .cards-sec {
    padding-bottom: 4rem;
  }
  .get-ready-left-heading {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .get-ready-sec {
    padding-bottom: 260px;
  }
  .worldslider-title.text-center {
    padding-bottom: 34px;
  }
  .worldsilder-sec .swiper-slide.swiper-slide-next {
    background: #d5dffb;
    -webkit-box-shadow: unset;
            box-shadow: unset;
    margin-top: 0rem;
  }
  .blog-middle {
    margin-top: 1rem;
  }

  /* about-sec */
  .image-title {
    font-size: 22px;
  }
  .pt-5 {
    padding-top: 0rem !important;
  }
  .about-sec-heading {
    font-size: 40px;
    margin-top: -31px;
  }
  .as-i-see-sec-inner img {
    width: 39%;
  }
  .pt-5 {
    padding-top: 0rem !important;
  }
  .our-team-desc {
    font-size: 13px;
  }
  .service-sec-inner {
    margin-top: 2rem;
  }
  .service-sec {
    margin-top: -4rem;
  }
  .service-sec-heading {
    margin-top: -14rem;
  }
  .service-sec-heading {
    font-size: 35px;
  }

  /* blog-sec */

  /* Sustainability-sec */

  .embrace-sec {
    padding-top: 2rem;
  }
  .our-focus {
    padding-top: 0rem;
  }
  .our-focus-inner {
    padding-top: 1rem;
  }

  /* resource-page */

  .resource-sec-title {
    font-size: 24px;
  }

  /* according-item */
  .according-main-title {
    font-size: 22px;
  }
  .service-firstCont-title {
    font-size: 30px;
    margin-top: 0;
}
.service-firstCont-para {
  margin-top: 15px;
  font-size: 18px;
}
.service-firstCont-rating {
  font-size: 22px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.blue-white-btn.new-blue-btn {
  padding-top: 10px;
}
.imgService-cont {
  margin-top: 30px;
}
element.style {
  padding: 30px 0px;
}
}
@media only screen and (max-width: 410px) {
  /* about-sec */

  .blog-image-sec {
    padding: 10rem 0rem;
  }
  .service-sec-heading {
    margin-top: -17rem;
  }
  
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}